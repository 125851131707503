import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import team from "./../../Images/svg/team.svg"
import Table from '../Table/Table';
import "./ReferidosAdmin.scss"
import "./../Table/table.scss"
import TableFilter from '../Table/TableFilter';

class ReferidosAdmin extends Component {

    constructor(){
        super()
        this.state = {
            search : "",
            filter : false,
            referidos : []
        }
    }

    componentWillMount() {
        this.getReferidos()
    }


    cleanInput = () => {
        this.setState(
            {search : ""}
        )
    }

    handleChange = (evt) => {
        this.setState(
            {
                search : evt.target.value
            }
        )
    }

    handleSearch = () => {
        this.setState(
            {
                filter :true
            }
        )
        this.handleFilter()
    }

    handleFilter = () => {
    }

    watchthemAll = () => {
        this.setState({
            filter : false
        })
        this.cleanInput()
    }

    getReferidos = () => {
        fetch("https://api-leia.herokuapp.com/referred/getAll").then(response => response.json()).then((myJson) => { this.setState({ referidos: myJson.reffereds }); console.log(this.state.referidos)})
        
    }




    render() {


        const headers = [
            {
                value : "Id cliente"
            },
            {
                value: "Nombre de cliente"
            },
            {
                value: "Código"
            },
            {
                value: "Usado"
            },
            {
                value: "Enviado a / fecha"
            }
        ]
        
        const rows2 = [
            {
                id: "5XHGFK",
                nombre: "Ivan Rodriguez",
                codigo: "98JCBJ",
                usado: "2 veces",
                enviadoa: "rerondo@hikn.com 09/08/12"
            },
            {
                id: "5XHGFK",
                nombre: "Simon Lopez",
                codigo: "98JCBJ",
                usado: "2 veces",
                enviadoa: "rerondo@hikn.com 09/08/12"
            },
            {
                id: "5XHGFK",
                nombre: "Ivan Rodriguez",
                codigo: "98JCBJ",
                usado: "2 veces",
                enviadoa: "rerondo@hikn.com 09/08/12"
            },
            {
                id: "5XHGFK",
                nombre: "Ivan Rodriguez",
                codigo: "98JCBJ",
                usado: "2 veces",
                enviadoa: "rerondo@hikn.com 09/08/12"
            },
            {
                id: "5XHGFK",
                nombre: "Ivan Rodriguez",
                codigo: "98JCBJ",
                usado: "2 veces",
                enviadoa: "rerondo@hikn.com 09/08/12"
            }
        ]
        let filt = this.state.filter;

        return (
            <div className="module referidos-admin">
                <Sidebar type="admin" />

                <div className="module-container">
                    <section className="content">

                        <div className="header">
                            <img src={team} alt="icon" className="icon" />
                            <div className="title">
                                <h2>Administración de referidos</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="main">
                            <div className="busqueda">
                                <input placeholder="Buscar por nombre de cliente" onChange={this.handleChange} value={this.state.search}></input>
                                <button className="btn-x" onClick={this.cleanInput}>x</button>
                                <button className="btn-buscar" onClick={this.handleSearch}>buscar</button>
                                <button className="btn-ver" onClick={this.watchthemAll}>ver todos</button>
                            </div>

                            <div className="referidos-table">
                                {/*< Table headers={headers} rows={rows} />*/}
                                {
                                    filt ? (
                                        <TableFilter headers={headers} rows={rows2} filterSearch={this.state.search} />
                                    ) :
                                        (
                                            <Table headers={headers} rows={rows2}  />
                                        )
                                }
                            </div>

                        </div>


                    </section>
                </div>

            </div>
        );
    }
}

export default ReferidosAdmin;
