import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Home from "./landingpage/components/Home/Home"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashboardClient from "./App/Components/Dashboard/DashboardClient"
import DashboardAdmin from "./App/Components/Dashboard/DashboardAdmin.js"
import DashboardSadmin from "./App/Components/Dashboard/DashboardSadmin"
import ConfiguracionCliente from './App/Components/Configuracion/ConfiguracionCliente';
import ConfiguracionAdmin from './App/Components/Configuracion/ConfiguracionAdmin';
import ConfiguracionSadmin from './App/Components/Configuracion/ConfiguracionSadmin';
import Error404 from './App/Components/404/Error404';
import ReferidosCliente from './App/Components/Referidos/ReferidosCliente';
import ReferidosAdmin from './App/Components/Referidos/ReferidosAdmin';
import ReferidosSadmin from './App/Components/Referidos/ReferidosSadmin';
import OfertasAdmin from './App/Components/Ofertas/OfertasAdmin';
import ProductosAdmin from "./App/Components/Productos/ProductosAdmin";
import ProductosSadmin from "./App/Components/Productos/ProductosSadmin";
import PagosAdmin from './App/Components/Pagos/PagosAdmin';
import AdminClientes from "./App/Components/AdministracionClientes/AdminClientes"
import PagosSadmin from './App/Components/Pagos/PagosSadmin';
import SadminClientes from './App/Components/AdministracionClientes/SadminClients';
import OfertasSadmin from "./App/Components/Ofertas/OfertasSadmin"
import SadminNinjas from "./App/Components/AdministracionNinjas/SadminNinjas"
import Marketplace from "./App/Components/Marketplace/Marketplace";
import CategoriasSadmin from "./App/Components/Categorias/CategoriasSadmin";
import Wishlist from "./App/Components/Wishlist/Wishlist";
import ShoppingCart from "./App/Components/Marketplace/ShoppingCart/ShoppingCart";
import PaymentSuccessClient from "./App/Components/Payment/PaymentSuccessClient";
import Cotizador from "./App/Components/Cotizador/Cotizador"
import AdminCotizador from "./App/Components/AdminCotizador/AdminCotizador";
import SadminCotizador from "./App/Components/AdminCotizador/SadminCotizador";
import ClientPedidos from "./App/Components/ClientPedidos/ClientPedidos";
import AdminPedidos from "./App/Components/AdminPedidos/AdminPedidos";
import SadminPedidos from "./App/Components/SadminPedidos/SadminPedidos";
// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)


function App() {
return (
    <Router>
            <div className="App">
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />

                <Route path="/client/" exact component={DashboardClient} />
                    <Route path="/client/configuracion" exact component={ConfiguracionCliente} />
                    <Route path="/client/referidos" exact component={ReferidosCliente} />
                    <Route path={"/client/marketplace"} exact component={Marketplace}  />
                    <Route path={"/client/cotizador"} exact component={Cotizador} />
                    <Route path={"/client/wishlist"} exact component={Wishlist} />
                    <Route path={"/client/carrito"} exact component={ShoppingCart} />
                    <Route path={"/client/pedidos"} exact component={ClientPedidos} />
                    <Route path={"/client/payment/success"} exact component={PaymentSuccessClient} />

                <Route path="/admin/" exact component={DashboardAdmin} />
                    <Route path="/admin/configuracion" exact component={ConfiguracionAdmin} />
                    <Route path="/admin/referidos" exact component={ReferidosAdmin} />
                    <Route path="/admin/ofertas" exact component={OfertasAdmin} />
                    <Route path="/admin/productos" exact component={ProductosAdmin} />
                    <Route path="/admin/pagos" exact component={PagosAdmin} />
                    <Route path="/admin/clientes" exact component={AdminClientes} />
                    <Route path={"/admin/cotizador"} exact component={AdminCotizador}/>
                    <Route path={"/admin/pedidos"} exact component={AdminPedidos} />

                <Route path="/sadmin/" exact component={DashboardSadmin} />
                    <Route path="/sadmin/configuracion" exact component={ConfiguracionSadmin} />
                    <Route path="/sadmin/referidos" exact component={ReferidosSadmin} />
                    <Route path="/sadmin/pagos" exact component={PagosSadmin} />
                    <Route path="/sadmin/clientes" exact component={SadminClientes} />
                    <Route path="/sadmin/ofertas" exact component={OfertasSadmin} />
                    <Route path="/sadmin/ninjas" exact component={SadminNinjas} />
                    <Route path="/sadmin/productos" exact component={ProductosSadmin} />
                    <Route path={"/sadmin/categorias"} exact component={CategoriasSadmin} />
                    <Route path={"/sadmin/cotizador"} exact component={SadminCotizador}/>
                    <Route path={"/sadmin/pedidos"} exact component={SadminPedidos} />

                <Route component={Error404} />
            </Switch>
            </div>
        
    </Router>

);
}

export default App;
