import React, {Component} from 'react';
import "./ShoppingCart.scss"
import Sidebar from "../../Sidebar/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShoppingCartProduct from "./ShoppingCartProduct/ShoppingCartProduct";
import SidePay from "./SidePay/SidePay";
import StatelessDialog from "../../StatelessDialog/StatelessDialog";
import DialogPayment from "../../DialogPayment/DialogPayment";

class ShoppingCart extends Component {

    constructor(){
        super()
        this.state = {
            subtotal : "",
            productos : "",
            dialog : {
                status : false,
                info : {
                    title : "",
                    tipo : "",
                    closer : ""
                }
            },
            dialogPayment : {
                status : false,
                info : {
                    title : "",
                    tipo : "",
                    message : "",
                    barcode  : ""
                }
            }
        }
    }

    componentWillMount() {
        this.getProductosCarrito()
        this.calcularSubTotal()
    }

    getProductosCarrito = () => {
        if(localStorage.getItem('carrito') !== null && localStorage.getItem('carrito').length > 0 ){
            this.setState({
                productos : JSON.parse(localStorage.getItem('carrito'))
            })
        }
    }

    calcularSubTotal = () => {
        if(localStorage.getItem('carrito') !== null && localStorage.getItem('carrito').length > 0 ){
            let subTotal = 0;
            let productos = JSON.parse(localStorage.getItem('carrito'));
            productos.forEach(
                p =>  {
                    subTotal += (Number(p.price) * p.cantity);
                }
            )
            this.setState({
                subtotal : subTotal
            })
        }
    }

    handleCantity = (index, cantity) => {
        let products = JSON.parse(localStorage.getItem('carrito'));
        products[index].cantity = cantity;
        localStorage.setItem('carrito', JSON.stringify(products))
        console.log(JSON.parse(localStorage.getItem('carrito')));
        this.getProductosCarrito()
        this.calcularSubTotal()
    }

    toggleDialog = () => {
        this.dialogHandler("none", "")
        this.dialogPaymentHandler("none", "", "", "")
        this.getProductosCarrito()
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    dialogPaymentHandler = (tipo, title, message, barcode) => {
        switch(tipo){
            case "ready":
                localStorage.setItem('carrito', "");
                this.setState({
                    productos: ""
                })
                this.setState({
                    dialogPayment : { status : true, info : { tipo: tipo, title: title, message: message, barcode : barcode  } }
                })
                break;
            case "none":
                this.setState({
                    dialogPayment : { status : false, info : { tipo: tipo, title: "", message: "", barcode: ""  } }
                })
        }
    }

    deleteFromShoppingCart = index => {
        this.dialogHandler("loading", 'Eliminando')
        let productos = JSON.parse(localStorage.getItem('carrito'));
        let deleted = productos.splice(this.props.index, 1);
        localStorage.setItem('carrito', JSON.stringify(productos));
        this.setState({
            productos : JSON.parse(localStorage.getItem('carrito'))
        })
        this.dialogHandler("ready", "Eliminado!")
        setTimeout(() => {
            this.dialogHandler("none", "")
        }, 700)
        this.calcularSubTotal()
    }


    render() {


        return (
            <div className={"module shopping-cart"}>
                <Sidebar type={"cliente"} />

                <div className="module-container-shopping">
                    <section className="content-shopping">
                        <div className="header">
                            <h2>  <FontAwesomeIcon icon={['fas', 'shopping-cart']} />  Carrito de compras</h2>
                        </div>

                        {
                            this.state.productos.length > 0 ?
                                (
                                    <div className="main-shopping">

                                        <div className="container-shopping">
                                            <div className="subtotal">
                                                <p> Subtotal : <span>$ {Intl.NumberFormat().format(this.state.subtotal)} MXN</span> </p>
                                            </div>

                                            <div className="products">
                                                {
                                                    this.state.productos.map(
                                                        (p, index) => <ShoppingCartProduct key={Math.random()} handleCantity={this.handleCantity} index={index} product={p} delete={this.deleteFromShoppingCart} />
                                                    )
                                                }
                                            </div>

                                        </div>

                                        <SidePay total={this.state.subtotal} productos={this.state.productos} dialogHandler={this.dialogHandler} dialogPaymentHandler={this.dialogPaymentHandler} />

                                    </div>
                                ) :
                                (
                                    <div className={"no-products"}>
                                        <span><FontAwesomeIcon icon={['far', 'frown-open']} /></span>
                                        <h2>No tienes productos en tu carrito </h2>
                                        <p>Da click en el <FontAwesomeIcon icon={['fas', 'shopping-cart']} /> de un producto del marketplace para agregarlo a tu carrito  </p>
                                    </div>
                                )
                        }



                    </section>
                </div>

                {
                    this.state.dialog.status ?
                        (
                            <StatelessDialog dialog={this.state.dialog} closer={ this.toggleDialog }/>
                        ) : null
                }

                {
                    this.state.dialogPayment.status ?
                        (
                            <DialogPayment dialog={this.state.dialogPayment} closer={ this.toggleDialog } />
                        ) : null
                }

            </div>
        );
    }
}

export default ShoppingCart;