import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./DialogPayment.scss";

const DialogPayment = (props) => (
    <div className={"dialog-payment"}>
        <div className="container">

            <span className={"closer"} onClick={props.closer}> <FontAwesomeIcon icon={['fas', 'times']} /> </span>

            <div className="status">
                {
                    props.dialog.info.tipo === "ready" ? (
                        <span><FontAwesomeIcon icon={['fas', 'check-circle']}/></span>
                ):
                        <span><FontAwesomeIcon icon={['fas', 'times']}/></span>
                }
                <h4>{props.dialog.info.title}</h4>
            </div>

            {
                props.dialog.info.tipo === "ready" ?
                    (
                        <div className="message">
                            <h3>{props.dialog.info.message}</h3>
                            <img src={props.dialog.info.barcode} alt=""/>
                        </div>
                    )
                    : null
            }

        </div>
    </div>
)

export default DialogPayment;