import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Footer.css"

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">

                    <div className="footer-links">
                        <ul>
                            <li><a href="#cover"><span><FontAwesomeIcon icon={['fas', 'home']} /></span><span className="footer-text">Home</span></a></li>
                            <li><a href="#servicios"><span><FontAwesomeIcon icon={['far', 'handshake']} /></span><span className="footer-text">Servicios</span></a></li>
                            <li><a href="#nosotros"><span><FontAwesomeIcon icon={['fas', 'users']} /></span><span className="footer-text">Sobre nosotros</span></a></li>
                            <li><a href="#testimonios"><span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span><span className="footer-text">Testimonial</span></a></li>
                            <li><a href="#contacto"><span><FontAwesomeIcon icon={['fas', 'phone']} /></span><span className="footer-text">Contacto</span></a></li>
                            {/* <li><a href="#ubicacion"><span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span><span className="footer-text">Ubicacion</span></a></li> */}
                        </ul>
                    </div>

                    <div className="footer-social-links">
                        <div>
                            <a href="https://www.facebook.com/leiamx/" ><p><span><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span></p></a>
                            <a href="https://wa.me/14775754038" ><p><span><FontAwesomeIcon icon={['fab', 'whatsapp']} /></span></p></a>
                            <a href="https://www.linkedin.com/company/leia-mx/" ><p><span><FontAwesomeIcon icon={['fab', 'linkedin']} /></span></p></a>
                            <a href="https://www.instagram.com/leiamex/" ><p><span><FontAwesomeIcon icon={['fab', 'instagram']} /></span></p></a>
                        </div>
                    </div>

                    <p className="footer-inf">
                        ©2021 Copyright. Leia Desarrollos Tecnológicos de México S.A. de C.V. Todos los derechos reservados.
                    </p>

                </div>
            </div>
        );
    }
}

export default Footer;
