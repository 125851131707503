import React, {Component} from 'react';
import "./ServiceDetails.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moneyicon from "./../../../Images/svg/notes.svg"
import truck from "./../../../Images/svg/mexico.svg"
import visa from "./../../../Images/svg/visa.svg"
import paypal from "./../../../Images/svg/paypal.svg"
import mastercard from "./../../../Images/svg/mastercard.svg"
import Servicio from "../Servicio/Servicio";
import StatelessDialog from "../../StatelessDialog/StatelessDialog";

class ServiceDetails extends Component {

    constructor(props){
        super(props)
        this.state = {
            dialog : { status: false , info : {} }
        }
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    render() {
        return (
            <div className={"service-detail"}>
                <div className="content">
                    <span className={"closer"} onClick={this.props.closer} >  <FontAwesomeIcon icon={['fas', 'times']}/> </span>

                    <div className="service-info">

                        <div className="images">
                            <div className="image">
                                <img src={this.props.servicio.image} alt={" "}/>
                            </div>
                        </div>

                        <div className="data">
                            <div className="name">
                                <h2>{this.props.servicio.name }</h2>
                            </div>
                            <div className="shopping-info">
                                <div className={"price"}>
                                    <img src={moneyicon} alt={" "}/>
                                    <p>$ {this.props.servicio.costRange}</p>
                                </div>
                            </div>
                            <div className="payment-info">
                                <div className="payment">
                                    <label> Categoría:  </label>
                                    <p> {this.props.servicio.principalCategory} </p>
                                </div>
                                <div className="payment">
                                    <label> Subcategoría:  </label>
                                    <p> {this.props.servicio.subCategory} </p>
                                </div>
                            </div>
                            <div className="buttons">
                                <button className={"messenger"}>
                                    <a target={"_blank"} href="https://www.facebook.com/leiamx/" ><FontAwesomeIcon icon={['fab', 'facebook-messenger']} /></a>
                                </button>
                                <button className={"whatsapp"}>
                                    <a target={"_blank"} href="https://wa.me/14775754038" ><FontAwesomeIcon icon={['fab', 'whatsapp']} /></a>
                                </button>
                                <button className={"phone"}>
                                    <a target={"_blank"} href="tel:+52 4775754038"><FontAwesomeIcon icon={['fas', 'phone']} /></a>
                                </button>
                                <button className={"email"}>
                                    <a href = "mailto: info@leia-mx.com"><FontAwesomeIcon icon={['fas', 'envelope']} /></a>
                                </button>
                            </div>

                        </div>

                        <div className="description">
                            <h3>Descripción:</h3>
                            <p>{this.props.servicio.description}</p>
                        </div>


                    </div>



                </div>

                {
                    this.state.dialog.status ? <StatelessDialog dialog={this.state.dialog}/> : null
                }

            </div>
        );
    }
}

export default ServiceDetails;