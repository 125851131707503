import React, {Component} from 'react';
import Sidebar from "./../Sidebar/Sidebar"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Marketplace.scss"
import Carrousel from "./Carousel/Carousel";
import MiniCard from "./MiniCard/MiniCard";
import CategoryList from "./CategoryList/CategoryList";
import Producto from "./Producto/Producto";
import ProductDetail from "./ProductDetails/ProductDetail";
import {Redirect} from "react-router-dom";
import StatelessDialog from "../StatelessDialog/StatelessDialog";

class Marketplace extends Component {

    constructor(){
        super()
        this.state = {
            categorias : [],
            categorySelected : [],
            productos : [],
            subcategorias : [],
            showProductDetail : { estatus : false, producto : [] },
            dialog  : {status : true, info : { tipo : "loading", title: "Cargando ... " }},
            productosCategory : []
        }
    }

    componentWillMount() {
        this.getCategorias()
        //this.getProductos()

    }

    getCategorias = () => {
        fetch("https://api-leia.herokuapp.com/category/getAll")
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        categorias: myJson.categories.slice(0, 4),
                        categorySelected : myJson.categories[0]
                    }
                )
                this.getProductos()
                this.setState( {
                    dialog : { status : false, info : {} }
                })
            })
    }

    getProductos = () => {
        fetch("https://api-leia.herokuapp.com/product/getAll", {
            mode: 'cors',
        })
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        productos: myJson.products, //.filter(p => p.status !== "inactiva")
                        productosCategory : myJson.products.filter( p => p.category === this.state.categorySelected.name )
                    }
                )
            })
    }

    filter = () => {
            fetch("https://api-leia.herokuapp.com/product/getAll", {
                mode: 'cors',
            })
                .then(response => response.json())
                .then((myJson) => {
                    myJson = myJson
                    this.setState(
                        {
                            productos: myJson.products.filter( p => p.category === this.state.categorySelected.name ), //.filter(p => p.status !== "inactiva")
                            productosCategory : myJson.products.filter( p => p.category === this.state.categorySelected.name )
                        }
                    )
                })
    }

    filterSubCategory = () => {
        if(this.state.subcategorias.length > 0){
            let pro = this.state.productosCategory
            let productos = []
            pro.forEach(
                p => {
                    if( this.state.subcategorias.includes( p.subcategories ) ) {
                        productos.push(p)
                    }
                }
            )
            this.setState({
                productos : productos
            })
        } else {
            this.filter()
        }
    }

    handleCategory = (e) => {
        let cat = this.state.categorias
        cat.forEach(c => c.selected = false)
        cat[e].selected = true
        this.setState({
            categorias : cat,
            categorySelected : cat[e],
        })
        //console.log(this.state.categorySelected.name)
        this.setState({
            subcategorias : []
        })
        this.filter()
    }

    handleSelectSubCategory = (subcategoria, method) => {
        if(method === "push"){
            let sub = this.state.subcategorias
            sub.push(subcategoria)
            this.setState({
                subcategorias : sub
            })
        } else{
            let sub = this.state.subcategorias
            sub.splice(sub.indexOf(subcategoria), 1)
            this.setState({ subcategorias : sub })
        }
        //console.log(this.state.subcategorias)
        this.filterSubCategory()
    }

    closeProductDetail = () => {
        this.setState( {
            showProductDetail : {
                estatus : false, producto : []
            }
        })
    }

    handleSeeMore = (e) => {
        let producto;
        this.state.productos.map(
            p => {
                if (p.id === e.target.id){
                    producto = p
                }
            }
        )
        this.setState({
            showProductDetail : {
                estatus : false,
                producto : producto
            }
        })
        this.setState({
            showProductDetail : {
                estatus : true,
                producto : producto
            }
        })
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }


    render() {

        if(localStorage.getItem('userId') === null || localStorage.getItem('userId') === 'null' || localStorage.getItem('userId') === "") {
            return (
                <Redirect to={"/sorry-not-logged"}/>
            )
        } else {
            return (
                <div className={"module marketplace"}>
                    <Sidebar type={"cliente"} />

                    <div className="module-container-marketplace">

                        <section className="content-marketplace">

                            <div className="header"> <h2>  <FontAwesomeIcon icon={['fas', 'store']} />  Marketplace </h2> </div>

                            {  <Carrousel /> }

                            <div className="main-marketplace">

                                {
                                    this.state.categorias.length > 0 ?
                                        (
                                            <div className="container-marketplace">

                                                <div className="categorias-row">
                                                    <div className="title">
                                                        <h2>Categorías </h2>
                                                        <p>Selecciona la categoría que quieres explorar</p>
                                                    </div>
                                                    {
                                                        <div className="categorias-cards">
                                                            {
                                                                this.state.categorias.map(
                                                                    (c, index) =>  <MiniCard key={index} svg={c.svg} nombre={c.name} selected={c.selected} index={index} event={this.handleCategory} />
                                                                )
                                                            }
                                                        </div>
                                                    }

                                                </div>

                                                <div className="marketplace-productos">
                                                    <div className="options">

                                                        <div className="option">
                                                            <div className="title">
                                                                <h2>{this.state.categorySelected.name}</h2>
                                                            </div>
                                                            <div className="list">
                                                                <ul className="sub-categories-list">
                                                                    {
                                                                        this.state.categorySelected.subcategories.map(
                                                                            sub =>
                                                                                <CategoryList category={sub} event={this.handleSelectSubCategory} key={sub.nombre} />
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="products">

                                                        {
                                                            this.state.productos.map (
                                                                producto => <Producto key={producto.id} producto={producto}  handlerDisplay={this.handleSeeMore} dialogHandler={this.dialogHandler} />
                                                            )
                                                        }


                                                    </div>
                                                </div>



                                            </div>
                                        ) : <p>Por favor recarga la página</p>
                                }

                            </div>

                        </section>
                    </div>

                    {
                        this.state.showProductDetail.estatus ?
                            (
                                <ProductDetail closer={this.closeProductDetail} productos={this.state.productos} producto={this.state.showProductDetail.producto}  handleSeeMore={this.handleSeeMore} />
                            ) : null
                    }

                    {
                        this.state.dialog.status ? <StatelessDialog  dialog={this.state.dialog} /> : null
                    }


                </div>
            );
        }

    }
}

export default Marketplace;