import React, {Component} from 'react';
import "./SidePayCard.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SidePayCard extends Component {

    constructor(props){
        super(props)
        this.state = {
            clicked : this.props.clicked
        }
    }

    render() {
        return (
            <div className={"side-pay-card "+ [this.props.clicked ? "clicked" : ""]} onClick={this.props.handleClick}>
                <span className={"checked"}> <FontAwesomeIcon icon={['fas', 'check-circle']} /> </span>
                <p>{this.props.title}</p>
                <img src={this.props.svg} alt=""/>
            </div>
        );
    }
}

export default SidePayCard;