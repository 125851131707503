import React, {Component} from 'react';
import "./ProductDetail.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moneyicon from "./../../../Images/svg/notes.svg"
import truck from "./../../../Images/svg/mexico.svg"
import visa from "./../../../Images/svg/visa.svg"
import paypal from "./../../../Images/svg/paypal.svg"
import mastercard from "./../../../Images/svg/mastercard.svg"
import Producto from "../Producto/Producto";
import StatelessDialog from "../../StatelessDialog/StatelessDialog";

/* FIXME:
        -- Algunos productos no tienen rate, por lo que no se pueden poner las estrellas de calificación porque aparecería un error de NaN
        -- El método addComment aparece como no encontrado (404 NOT FOUND) en el servidor
 */

/*
    TODO:
        -- Para desplegar las estrellas en base a la calificación del producto, hay que primero redondear la cifra y en un for agregar cada estrella

 */

class ProductDetail extends Component {

    constructor(props){
        super(props)
        this.state = {
            selectedImage : 0,
            dialog : { status: false , info : {} },
            makeComment : false
        }
    }

    componentWillMount() {
        console.log(this.props.producto)
        console.log("the rate is " + Math.round( Number(this.props.producto.rate) ))
    }

    handleSelectedImage = (e) => {
        this.setState({
            selectedImage : e.target.id
        })
    }

    addToCart = (e) => {
        this.dialogHandler("loading", "Agregando al carrito...")
        if(  localStorage.getItem('carrito') === null || localStorage.getItem('carrito').length === 0 ||  localStorage.getItem('carrito') === "null"  ) {
            let carrito = [];
            //carrito.push(e.target.id);
            let p = this.props.producto;
            p.cantity = "1"
            carrito.push(p)
            localStorage.setItem('carrito', JSON.stringify(carrito));
            setTimeout(() => this.dialogHandler("ready", "Agregado al carrito!"), 500)
            setTimeout(() => this.dialogHandler("none", ""), 500)
        } else {
            //console.log(  JSON.parse(localStorage.getItem('carrito')) )
            let carrito = JSON.parse(localStorage.getItem('carrito'));
            //carrito.push(e.target.id)
            let p = this.props.producto;
            p.cantity = "1"
            carrito.push(p)
            localStorage.setItem('carrito', JSON.stringify(carrito))
            setTimeout(() => this.dialogHandler("ready", "Agregado al carrito!"), 500)
            setTimeout(() => this.dialogHandler("none", ""), 1000)
        }
    }

    addToWishlist = () => {
        this.dialogHandler("loading", "Agregando producto");
        let data = {
            id: localStorage.getItem('userId'),
            product_id: this.props.producto.id
        }
        fetch("https://api-leia.herokuapp.com/user/wishList/insert", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                this.dialogHandler("ready", "Agregado a la wishlist!")
                setTimeout( () => {
                    this.dialogHandler("none", "")
                }, 700)
            })
            .catch(error => {
                console.error('Error:', error.message)
                this.dialogHandler("error", "Ocurrió un error")
                setTimeout( () => {
                    this.dialogHandler("none", "")
                }, 700)
            });
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    toggleComment = () => {
        this.setState({
            makeComment : !this.state.makeComment
        })
    };

    enviarComentario = () => {
        this.dialogHandler("loading", "Enviando comentario...")
        let data = {
            comment : this.inputComment.value,
            id_product : this.props.producto.id,
            id_user : localStorage.getItem('userId')
        }
        fetch("https://api-leia.herokuapp.com/product/addComment", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log(response)
                this.dialogHandler("ready", "Agregado a la wishlist!")
                setTimeout( () => {
                    this.dialogHandler("none", "")
                }, 700)
            })
            .catch(error => {
                console.error('Error:', error.message)
                this.dialogHandler("error", "Ocurrió un error")
            });
    }

    render() {
        return (
            <div className={"product-detail"}>
                <div className="content">
                    <span className={"closer"} onClick={this.props.closer} >  <FontAwesomeIcon icon={['fas', 'times']}/> </span>

                    <div className="product-info">

                        <div className="images">
                            <div className="image">
                                <div className="img-icons">
                                    {
                                        this.props.producto.photos.map(
                                            (p, index) => <img src={p} key={p} id={index} onClick={this.handleSelectedImage} alt={" "}/>
                                        )
                                    }
                                </div>
                                <img src={this.props.producto.photos[this.state.selectedImage]} alt={" "}/>
                            </div>
                        </div>

                        <div className="data">
                            <div className="name">
                                <h2>{this.props.producto.name + " " + this.props.producto.brand }</h2>
                            </div>
                            <div className="shopping-info">
                                <div className={"price"}>
                                    <img src={moneyicon} alt={" "}/>
                                    <p>$ {Intl.NumberFormat().format(this.props.producto.price)}</p>
                                </div>
                                <div className={"delivery"}>
                                    <img src={truck} alt={" "}/>
                                    <p>Mexico</p>
                                </div>
                            </div>
                            <div className="payment-info">
                                <div className="payment">
                                    <label> Marca:  </label>
                                    <p> {this.props.producto.brand} </p>
                                </div>
                                {
                                    this.props.producto.rate.length > 0 ?
                                        (
                                            <div className="payment">
                                                <label> Calificación:  </label>
                                                <p>{this.props.producto.rate}</p>
                                            </div>
                                        ) : null
                                }
                                <div className="payment">
                                    <label> Métodos de pago:  </label>
                                    <div className="icons">
                                        <img src={visa} alt={" "}/>
                                        <img src={paypal} alt={" "}/>
                                        <img src={mastercard} alt={" "}/>
                                    </div>
                                </div>
                            </div>
                            <div className="buttons">
                                <button className={"wish"} onClick={this.addToWishlist}>
                                    <FontAwesomeIcon icon={['fas', 'heart']} />
                                </button>
                                <button className={"cart"} id={this.props.producto.id} onClick={this.addToCart}>
                                    <FontAwesomeIcon icon={['fas', 'shopping-cart']} />
                                </button>
                            </div>

                        </div>

                        <div className="description">
                            <p>{this.props.producto.description}</p>
                        </div>

                        {/*
                        <div className="relateds">
                            <div className="title">
                                <h3>Productos Relacionados</h3>
                            </div>
                            <div className="productos">
                                {
                                    this.props.productos.map (
                                        producto => <Producto key={producto.id} producto={producto}  handlerDisplay={this.props.handleSeeMore} handlerWish={this.handleWishList} />
                                    )
                                }
                            </div>

                        </div>

                        */}

                        <div className="comments">

                            <button onClick={this.toggleComment}>
                                Agregar un comentario <FontAwesomeIcon icon={['fas', 'plus']} />
                            </button>

                            {
                                this.state.makeComment ?
                                    (
                                        <section>
                                            <textarea ref={inputComment => this.inputComment = inputComment} />
                                            <button className={"send-comment"} onClick={this.enviarComentario}>Enviar</button>
                                            <button className={"cancel-comment"} onClick={this.toggleComment}>Cancelar</button>
                                        </section>
                                    ) : null
                            }


                            {
                                this.props.producto.comments !== null ?
                                    (
                                       this.props.producto.comments.map (
                                           c =>
                                               <div className={"comment"}>
                                                    <h3>Comentario por:</h3>
                                                   <p>{c.comment}</p>
                                               </div>
                                       )
                                    ) : <p>No hay comentarios todavía</p>
                            }

                        </div>

                    </div>


                </div>

                {
                    this.state.dialog.status ? <StatelessDialog dialog={this.state.dialog}/> : null
                }

            </div>
        );
    }
}

export default ProductDetail;