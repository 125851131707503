import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Producto extends Component {

    constructor(){
        super()
        this.state = {
            seeMore : false
        }
    }

    toggleSeeMore = (e) => {
       this.setState({
           seeMore : !this.state.seeMore
       })
    }

    handleWishList = (e) => {
        this.props.dialogHandler("loading", "Agregando producto");
        let data = {
            id: localStorage.getItem('userId'),
            product_id: e.target.id
        }
        fetch("https://api-leia.herokuapp.com/user/wishList/insert", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log(response)
                this.props.dialogHandler("ready", "Agregado a la wishlist!")
                setTimeout( () => {
                    this.props.dialogHandler("none", "")
                }, 1000)
            })
            .catch(error => {
                console.error('Error:', error.message)
                this.props.dialogHandler("ready", "Agregado a la wishlist!")
                setTimeout( () => {
                    this.props.dialogHandler("none", "")
                }, 1000)
            });

    }


    render() {
        return (
            <div className="product">
                <span id={this.props.producto.id} onClick={this.handleWishList} className={"product-heart"}> <FontAwesomeIcon icon={['fas', 'heart']}/> </span>
                <img src={this.props.producto.photos[0]} alt={" "} />
                <div className="information">

                    {
                        this.state.seeMore ?
                            (
                                <div>
                                    <div className="look">
                                        <span className={"see-up"} onClick={this.toggleSeeMore}> <FontAwesomeIcon icon={['fas', 'arrow-circle-down']}  /> </span>
                                    </div>
                                    <div className="inf">
                                        <div className="row">
                                            <h3>{this.props.producto.name}</h3>
                                        </div>
                                        <div className="row">
                                            <label>Marca</label>
                                            <p>{this.props.producto.brand}</p>
                                        </div>
                                        <div className="row">
                                            <label>Precio</label>
                                            <p>$ {Intl.NumberFormat().format(this.props.producto.price)} </p>
                                        </div>
                                        <div className="row">
                                            <button id={this.props.producto.id} onClick={this.props.handlerDisplay}>
                                                Ver más
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div>
                                    <div className="look">
                                        <span className={"see-up"} onClick={this.toggleSeeMore}> <FontAwesomeIcon icon={['fas', 'arrow-circle-up']}  /> </span>
                                    </div>
                                    <div className="inf">
                                        <h3>{this.props.producto.name}</h3>
                                        <p> $ {Intl.NumberFormat().format(this.props.producto.price)} </p>
                                    </div>
                                </div>
                            )
                    }


                </div>
            </div>
        );
    }
}

export default Producto

