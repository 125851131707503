import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import "./Dialog.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import firebase from "firebase";



class Dialog extends Component {


    constructor(props){
        super(props)
        this.state = {
            category : {

            }
        }
    }

    handlerCat = i => {
        this.setState({
            category : {
                ...this.state.category, nombre : i.target.value
            }
        })
    }

    handlerSub = (selectedOption) => {
        let subs = []
        selectedOption.forEach(
            op => subs.push({nombre : op.value})
        )
        this.setState({
            category : {
                ...this.state.category, subcategorias: subs
            }
        })
    }


    handleNew = (e) => {
        e.preventDefault()
        this.props.handler(this.state.category)
    }


    handlerSVG = (f) => {
        let file = f.target.files[0]
        const storageref = firebase.storage().ref(`/Marketplace/Categorias/${file.name}`);
        const task = storageref.put(file)
        task.on(firebase.storage.TaskEvent.STATE_CHANGED,
            {
                'complete': () => {
                    storageref.getDownloadURL().then(url => {
                        this.setState({
                            category : {
                                ...this.state.category, svg : url
                            }
                        })
                        console.log(url)
                    })

                }
            })
    }



    render() {

        return (
            <div className={"dialog"}>
                <div className="dialog-content">
                    <span className={"closer"} onClick={this.props.closer} >  <FontAwesomeIcon icon={['fas', 'times']}/> </span>
                    <h2> {this.props.dialog.title} </h2>

                    <div className={"content " + [this.props.dialog.type] }>

                        {
                            this.props.dialog.type === "add-categoria" ?
                                (
                                    <div className={"form-container"}>

                                        <form>

                                            <div className="input-row">
                                                <input type="file" onChange={this.handlerSVG} required />
                                                <label> Ícono </label>
                                            </div>

                                            <div className="input-row">
                                                <input type="text" value={this.props.category} required placeholder={"Nombre de categoría"} onChange={this.handlerCat} />
                                                <label> Nombre de categoría </label>
                                            </div>

                                            <div className="input-row">
                                                <CreatableSelect isMulti onChange={this.handlerSub} placeholder={"Escribir subcategorías"} />
                                                <label> Subcategorías </label>
                                            </div>



                                            <div className="input-row">
                                                <button className={"btn-save"} onClick={ this.handleNew }> Agregar </button>
                                            </div>

                                        </form>

                                    </div>
                                ) : null
                        }

                    </div>

                </div>
            </div>
        );
    }
}

export default Dialog;

