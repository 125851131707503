import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ClientPedidos.scss";
import PedidoClient from "./PedidoClient/PedidoClient";

class ClientPedidos extends Component {

    constructor(props){
        super(props)
        this.state = {
            pedidos : []
        }
    }

    componentWillMount() {
        //this.getPedidos()
    }

    getPedidos = () => {
        let data = {
            idClient : localStorage.getItem('userId'),
            //resto de los datos
        }
    };

    render() {
        return (
            <div className={"module client-pedidos"}>
                <Sidebar type={"cliente"} />

                <div className="module-container-pedidos">
                    <section className="content-pedidos">

                        <div className="header">
                            <h2>  <FontAwesomeIcon icon={['fas', 'truck']} />  Pedidos </h2>
                        </div>

                        <div className="main">

                            <h2>Todos mis pedidos</h2>

                            <div className="pedidos">
                                {/*
                                    this.state.pedidos.map(
                                        pedido => <PedidoClient pedido={pedido}/>
                                    )
                                */}
                            </div>

                        </div>

                    </section>
                </div>

            </div>
        );
    }
}

export default ClientPedidos;