import React, { Component } from 'react';
import "./error404.css"
import {Link} from "react-router-dom"

class Error404 extends Component {
    render() {
        return (
            <div className="error404 bg-purple">
                <div class="stars">
            <div class="custom-navbar">
                    <div class="brand-logo">
                        
                </div>
                        
                    </div>
                    <div class="central-body">
                        <img alt="error" class="image-404" src="http://salehriaz.com/404Page/img/404.svg" width="300px" />
                        <Link to="/home"><a href="http://salehriaz.com/404Page/404.html" class="btn-go-home" target="_blank" rel="noopener noreferrer">GO BACK HOME</a></Link>
            </div>
                        <div class="objects">
                            <img alt="error"class="object_rocket" src="http://salehriaz.com/404Page/img/rocket.svg" width="40px" />
                                <div class="earth-moon">
                                    <img alt="error" class="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" width="100px" />
                                        <img alt="error" class="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" width="80px"   />
                </div>
                                        <div class="box_astronaut">
                                            <img alt="error" class="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px" />
                </div>
                                        </div>
                                        <div class="glowing_stars">
                                            <div class="star"></div>
                                            <div class="star"></div>
                                            <div class="star"></div>
                                            <div class="star"></div>
                                            <div class="star"></div>

                                        </div>

        </div>
        </div>
        );
    }
}

export default Error404;
