import React, { Component } from 'react';
import "./table.scss"

class TableFilter extends Component {

    componentDidUpdate() {
        console.log("updated")
    }


    render() {

/*
        let elrows = this.props.rows.filter(
            el => el[1].value == this.props.filterSearch
        )
*/

        let elrows = this.props.rows.filter(
            el => el.nombre.includes(this.props.filterSearch)
        )


        return (
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr className="headers">
                            {this.props.headers.map(
                                header => <th key={header.value}>{header.value}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            elrows.map(row =>
                                <tr key={Math.random()}>
                                    <td>{row.id}</td>
                                    <td>{row.nombre}</td>
                                    <td>{row.codigo}</td>
                                    <td>{row.usado}</td>
                                    <td>{row.enviadoa}</td>
                                </tr>

                            )
                        }

                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableFilter;

