import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./CategoryList.scss"

class CategoryList extends Component {

    constructor(props){
        super(props)
        this.state = {
            clicked : false
        }
    }

    handleClick = () => {
        this.setState({
            clicked : !this.state.clicked
        })
        if(this.state.clicked === false ){
            this.props.event(this.props.category.nombre, "push")
        } else {
            this.props.event(this.props.category.nombre, "remove")
        }
    }


    render() {



        return (
            <li
                id={this.props.category.nombre}
                className={"category-list " + [  this.state.clicked ? "category-list-clicked" : "category-list-not-clicked"  ] }
                onClick={this.handleClick} >
                <span>
                    { this.state.clicked ? <FontAwesomeIcon icon={['far', 'check-circle']} /> : <FontAwesomeIcon icon={['far', 'circle']} /> }
                </span>
                {this.props.category.nombre}
            </li>
        );
    }
}

export default CategoryList;