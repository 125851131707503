/* eslint-disable */
import React, { Component } from 'react';
import "./Sidebar.css"
import Leia from "./../../Images/logo.png"
import {Link} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Sidebar extends Component {

    
    render() {


        const linksClients = [
            { 
                key : "1",
                name : "Home",
                icon : ['fas', 'home'],
                url : "/client"    
            },
            {
                key: "12",
                name: "Pedidos",
                icon: ['fas', 'truck'],
                url: "/client/pedidos"
            },
            {
                key: "2",
                name: "Marketplace",
                icon: ['fas', 'store'],
                url: "/client/marketplace"
            },
            {
                key: "8",
                name: "Cotizador",
                icon: ['fas', 'calculator'],
                url: "/client/cotizador"
            },
            {
                key: "3",
                name: "Carrito de compras",
                icon: ['fas', 'shopping-cart'],
                url: "/client/carrito"
            },
            {
                key: "4",
                name: "VR",
                icon: ['fas', 'heart'],
                url: "/client/wishlist"
            },
            {
                key: "5",
                name: "Referidos",
                icon: ['fas', 'user-friends'],
                url: "/client/referidos"
            },
            {
                key: "6",
                name: "Configuración",
                icon: ['fas', 'cog'],
                url: "/client/configuracion"
            },
            {
                key: "7",
                name: "Log out",
                icon: ['fas', 'door-open'],
                url: "/home",
                action : "logout"
            }
        ]
        const linksAdmin = [
            {
                key: "1",
                name: "Home",
                icon: ['fas', 'home'],
                url: "/admin"
            },
            {
                key: "2",
                name: "Pagos",
                icon: ['fas', 'hand-holding-usd'],
                url: "/admin/pagos"
            },
            {
                key: "3",
                name: "Productos",
                icon: ['fas', 'store'],
                url: "/admin/productos"
            },
            {
                key: "11",
                name: "Cotizador",
                icon: ['fas', 'calculator'],
                url: "/admin/cotizador"
            },
            {
                key: "4",
                name: "Referidos",
                icon: ['fas', 'user-plus'],
                url: "/admin/referidos"
            },
            {
                key: "5",
                name: "Ofertas",
                icon: ['fas', 'tags'],
                url: "/admin/ofertas"
            },
            {
                key: "6",
                name: "Clientes",
                icon: ['fas', 'users'],
                url: "/admin/clientes"
            },
            {
                key: "8",
                name: "Configuración",
                icon: ['fas', 'cog'],
                url: "/admin/configuracion"
            },
            {
                key: "9",
                name: "Log out",
                icon: ['fas', 'door-open'],
                url: "/home",
                action : "logout"
            }
        ]
        const linksSadmin = [
            {
                key: "1",
                name: "Home",
                icon: ['fas', 'home'],
                url: "/sadmin"
            },
            {
                key: "2",
                name: "Pagos",
                icon: ['fas', 'hand-holding-usd'],
                url: "/sadmin/pagos"
            },
            {
                key: "3",
                name: "Productos",
                icon: ['fas', 'store'],
                url: "/sadmin/productos"
            },
            {
                key: "11",
                name: "Cotizador",
                icon: ['fas', 'calculator'],
                url: "/sadmin/cotizador"
            },
            {
                key: "4",
                name: "Referidos",
                icon: ['fas', 'user-plus'],
                url: "/sadmin/referidos"
            },
            {
                key: "5",
                name: "Ofertas",
                icon: ['fas', 'tags'],
                url: "/sadmin/ofertas"
            },
            {
                key: "6",
                name: "Clientes",
                icon: ['fas', 'users'],
                url: "/sadmin/clientes"
            },
            {
                key: "7",
                name: "Administradores",
                icon: ['fas', 'user-ninja'],
                url: "/sadmin/ninjas"
            },
            {
                key: "8",
                name: "Categorías",
                icon: ['fab', 'itch-io'],
                url: "/sadmin/categorias"
            },
            {
                key: "9",
                name: "Configuración",
                icon: ['fas', 'cog'],
                url: "/sadmin/configuracion"
            },
            {
                key: "10",
                name: "Log out",
                icon: ['fas', 'door-open'],
                url: "/home",
                action : "logout"
            }
        ]
        let links = []
        if(this.props.type === "cliente"){
            links = linksClients;
        }
        else if (this.props.type === "admin") {
            links = linksAdmin;
        }
        else if (this.props.type === "sadmin") {
            links = linksSadmin;
        }



        return (
            <div className={"sidebar " + "sidebar-"+this.props.type}>
                <div className="sidebar-content">
                    <div className="image">
                        <img src={Leia} alt="Leia" />
                    </div>
                    <div className="sidebar-links">
                        <ul>
                        { links.map(
                            link =>
                            <Link to={link.url} key={link.key}>
                                    <li className={"link " + "link-"+this.props.type}> <FontAwesomeIcon icon={link.icon} /> </li>
                            </Link>
                            ) 
                        }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
