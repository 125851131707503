import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from "react-router-dom"
import carrito from "./../../../Images/svg/carrito.svg"
import money from "./../../../Images/svg/money.svg"
import factory from "./../../../Images/svg/factory.svg"
import team from "./../../../Images/svg/team.svg"
import banking from "./../../../Images/svg/banking.svg"
import plane from "./../../../Images/svg/plane.svg"
import "./UserCards.css"
import "./../../../../libraries/hover.css"

class UserCards extends Component {

    constructor(){
        super()
        this.state = {
            cCarrito : "0",
            cTotalCarrito : "0"
        }
    }

    componentWillMount() {
        if( localStorage.getItem('carrito') === "" ||  localStorage.getItem('carrito') === null ){

        } else {

                let tot = 0
                JSON.parse(localStorage.getItem('carrito')).forEach(
                    p => tot += Number(p.price) * Number(p.cantity)
                )

            this.setState({
                cCarrito : JSON.parse(localStorage.getItem('carrito')).length,
                cTotalCarrito : "$ " + tot
            })
        }
    }

    render() {

        const cardsCliente = [
            {
                icon : carrito,
                number : this.state.cCarrito ,
                name : "items en carrito",
                buttonText : "ver",
                buttonIcon : ['fas', 'search']
            },
            {
                icon: money,
                number: this.state.cTotalCarrito,
                name: "Total del carrito",
                buttonText: "pagar",
                buttonIcon: ['fas', 'credit-card']
            },
            {
                icon: factory,
                number: "0",
                name: "Servicios contratados",
                buttonText: "historial",
                buttonIcon: ['fas', 'search']
            }
        ]
        const cardsAdmin = [
            {
                icon: team,
                number: "30",
                name: "Usuarios finales",
                buttonText: "ver",
                buttonIcon: ['fas', 'search']
            },
            {
                icon: banking,
                number: "40",
                name: "Ofertas",
                buttonText: "ver",
                buttonIcon: ['fas', 'credit-card']
            },
            {
                icon: plane,
                number: "0",
                name: "Mensajes ",
                buttonText: "ver",
                buttonIcon: ['fas', 'search']
            }
        ]

        let cardsI = []

        if(this.props.type === "cliente") {
            cardsI = cardsCliente
        }
        else if (this.props.type === "admin") {
            cardsI = cardsAdmin
        }
        else if (this.props.type === "sadmin") {
            cardsI = cardsAdmin
        }

        return (
            
            <div className="dashboard-cards">
                {
                    cardsI.map(
                        card => 
                        <div className="dashboard-card card-client" key={card.name}> 
                            <div className="card-title">
                                    <img src={card.icon} alt="svg" />
                                    <div className="card-title-text">
                                        <h3>
                                            {card.number}
                                        </h3>
                                    </div>
                            </div>
                            <div className="card-text">
                                <p>
                                    {card.name}
                                </p>
                            </div>
                            <Link to="/home" >
                                    <button className="hvr-icon-forward">
                                        <span>
                                            {card.buttonText}
                                        </span>
                                        <span>
                                            <FontAwesomeIcon icon={card.buttonIcon} className="hvr-icon" />
                                        </span>
                                    </button>
                            </Link>
                        </div>
                    )
                }
            </div>

        );
    }
}

export default UserCards;
