import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Contacto.css"

class Contacto extends Component {
    render() {
        return (
            <div className="contacto" id="contacto">
                <div className="container">
                    <h2 className="contacto-title"> ¿Listo para el próximo nivel? </h2>
                    <p className="contacto-description"> Leia es el partner que estabas buscando. Llena el siguiente formulario para contactarnos. </p>
                    
                    <div className="contacto-datos">

                        <div className="contacto-form">
                            <h3> Tus datos </h3>
                            <form className="form" action="mailto:info@leia-mx.com">

                                <div className="form-element">
                                    <span><FontAwesomeIcon  icon={['fas', 'user']} /></span>
                                    <input type="text" name="nombre" placeholder="Nombre" required />
                                </div>
                                <div className="form-element">
                                    <span><FontAwesomeIcon icon={['fas', 'phone']} /></span>
                                    <input type="phone" name="telefono" placeholder="Teléfono" required />
                                </div>
                                <div className="form-element">
                                    <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span>
                                    <input type="email" name="email" placeholder="Email" required />
                                </div>

                                <button className="contacto-btn">
                                    Enviar
                                </button>


                            </form>
                        </div>


                        <div className="contacto-inf">
                            <h3> Nuestros datos </h3>
                            <p> <span> <FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> </span> León, Gto.</p>
                            <p> <span> <FontAwesomeIcon icon={['fas', 'envelope']} /> </span> isidro@leia-mx.com</p>
                            <p> <span> <FontAwesomeIcon icon={['fas', 'phone']} /> </span> +52 477 575 40 38</p>
                            <div className="contacto-social-media">
                                    <a href="https://www.facebook.com/leiamx/" ><p><span><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span></p></a>
                                    <a href="https://wa.me/14775754038" ><p><span><FontAwesomeIcon icon={['fab', 'whatsapp']} /></span></p></a>
                                    <a href="https://www.linkedin.com/company/leia-mx/" ><p><span><FontAwesomeIcon icon={['fab', 'linkedin']} /></span></p></a>
                                    <a href="https://www.instagram.com/leiamex/" ><p><span><FontAwesomeIcon icon={['fab', 'instagram']} /></span></p></a>
                            </div>

                        </div>

                    </div>

                    
                </div>
            </div>
        );
    }
}

export default Contacto;
