import React, {Component} from 'react';
import "./AdminCotizador.scss"
import Dialog from "../Dialog/Dialog";
import "./../styles/TableList.scss"
import Sidebar from "../Sidebar/Sidebar";
import {Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ofert from "../../Images/svg/calculator.svg";
import DialogNewService from "./DialogNewService/DialogNewService";
import DialogUpdateService from "./DialogUpdateService/DialogUpdateService"
import Servicio from "./Servicio/Servicio";
import StatelessDialog from "../StatelessDialog/StatelessDialog";

class SadminCotizador extends Component {

    constructor(){
        super()
        this.state = {
            dialog : false,
            dialogStateless : {status : false, info : {}},
            dialogUpdate : { status : false, servicio : "" },
            servicios : []
        }
    }

    componentWillMount() {
        this.getServicios()
    }

    getServicios = () => {
        fetch("https://api-leia.herokuapp.com/quoting/get", {
            mode: 'cors',
        })
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        servicios: myJson.info
                    }
                )
                console.log(myJson.info)
            })
    }

    handleNewServicio = () => {
       this.setState({
           dialog : true
       })
    }

    closeDialog = () => {
        this.setState({
            dialog : false,
            dialogUpdate : { status : false, servicio : ""}
        })
        this.getServicios()
    }

    update = (servicio) => {
        this.setState({
            dialogUpdate : {
                status : true,
                servicio : servicio
            }
        })
    }

    delete = (servicio) => {
        let data = {
            id : servicio.id,
            status : "inactive"
        }
        this.dialogHandler("loading", "Activando..")
        fetch("https://api-leia.herokuapp.com/quoting/update", {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                this.dialogHandler("ready", "Activado!")
                setTimeout(() => this.dialogHandler("none", ""), 700)
                this.getServicios()
            })
            .catch(error => {
                console.error('Error:', error)
                this.dialogHandler("error", "Ocurrió un error")
            });
    }

    activate = (servicio) => {
        let data = {
            id : servicio.id,
            status : "active"
        }
        this.dialogHandler("loading", "Activando..")
        fetch("https://api-leia.herokuapp.com/quoting/update", {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                this.dialogHandler("ready", "Activado!")
                setTimeout(() => this.dialogHandler("none", ""), 700)
                this.getServicios()
            })
            .catch(error => {
                console.error('Error:', error)
                this.dialogHandler("error", "Ocurrió un error")
            });
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    render() {
        return (
            <div className={"module module-admin-cotizador"}>
                <Sidebar type="sadmin" />

                <div className="module-container">
                    <section className="content">

                        <div className="header">
                            <img src={ofert} alt="icon" className="icon" />
                            <div className="title">
                                <h2>Administración de servicios (cotizador)</h2>
                            </div>
                            <hr />
                        </div>


                        <div className="main">

                            <div className="title-servicios">
                                <h2>Todos los servicios</h2>
                                <button onClick={this.handleNewServicio}> <FontAwesomeIcon icon={['fas', 'plus']} /> Agregar servicio</button>
                            </div>

                            <div className="table-list-servicios">
                                {
                                    this.state.servicios.map (
                                        s => <Servicio servicio={s} key={Math.random()} delete={this.delete} update={this.update} activate={this.activate} />
                                    )
                                }
                            </div>


                        </div>

                    </section>
                </div>

                {
                    this.state.dialog === true ?
                        (
                            <DialogNewService close={this.closeDialog}/>
                        )  : null
                }

                {
                    this.state.dialogUpdate.status === true ?
                        (
                            <DialogUpdateService close={this.closeDialog} servicio={this.state.dialogUpdate.servicio}/>
                        ) : null
                }

                {
                    this.state.dialogStateless.status === true ?
                        (
                            <StatelessDialog dialog={this.state.dialogStateless}/>
                        ) : null
                }

            </div>
        );
    }
}

export default SadminCotizador;