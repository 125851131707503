import React, { Component } from 'react';
import Sidebar from "../Sidebar/Sidebar";
import ofert from "../../Images/svg/carrito.svg";
import "./Productos.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase";
import logo from "./../../Images/sa.jpg";
import Select from "react-select"
import StatelessDialog from "../StatelessDialog/StatelessDialog";

class ProductosAdmin extends Component {

    constructor() {
        super()
        this.state = {
            categorias : [],
            cats : [],
            subcats : [],
            dialogUploadPhotoForUpdate: false,
            visibleVerTodos: true,
            visibleAgregar: false,
            visibleActualizar: false,
            index: "0",
            dialogSelectProductUpdate: false,
            photoUpdate: File,
            products: [],
            pname: "",
            pbrand: "",
            pprice: "",
            pdesc: "",
            pfoto: "",
            purl: "",
            psubcategories : [],
            pcategory : [],
            psubCategory : [],
            ptype: "Product",
            uploadValue: 0,
            picture: logo,
            pictures: [],
            picturesUp: [],
            urls: [],
            agregar: false,
            dialog : { status: false , info : {} },
            newId: "",
            productUpdate: { id: "", name: "", brand: "", price: "", type: "", description: "", url: "", photos: [], category : [] }
        }
    }

    componentWillMount() {
        this.getProducts()
        this.getCategorias()
    }

    handleUpInputName = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, name: e.target.value } })
        }
    }
    handleUpInputBrand = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, brand: e.target.value } })
        }
    }
    handleUpInputType = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, type: e.target.value } })
        }
    }
    handleUpInputPrice = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, price: e.target.value } })
        }
    }
    handleUpInputDescription = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, description: e.target.value } })
        }
    }
    handleUpInputUrl = (e) => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            this.setState({ productUpdate: { ...this.state.productUpdate, url: e.target.value } })
        }
    }

    visActualizar = () => { this.setState({ visibleActualizar: true, visibleAgregar: false, visibleVerTodos: false }) }
    visAgregar = () => { this.setState({ visibleActualizar: false, visibleAgregar: true, visibleVerTodos: false }) }
    visVerTodos = () => { this.getProducts(); this.setState({ visibleActualizar: false, visibleAgregar: false, visibleVerTodos: true }) }


    deleteAllProducts = () => {
        let ids = []
        this.state.products.forEach(p => {
            ids.push(p.id)
        });

        ids.forEach(id => {
            let data = {
                id: id
            }
            fetch("https://api-leia.herokuapp.com/product/delete", {
                method: 'POST',
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then(response => {
                    console.log('Success:', response);
                })
                .catch(error => console.error('Error:', error));
        })

    }

    getProducts = () => {  // FUNCIONA
        fetch("https://api-leia.herokuapp.com/product/getAll", {
            mode: 'cors',
        })
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        products: myJson.products //.filter(p => p.status !== "inactiva")
                    }
                )
                this.forceUpdate()
            })
    }

    getCategorias = () => { // FUNCIONA
        fetch("http://localhost:5000/category/getAll")
            .then(response => response.json())
            .then((myJson) => {
                let cats = myJson.categories
                let cat = []
                cats.forEach(
                    ca => cat.push({ label : ca.name, value : ca.name })
                )
                this.setState({
                    categorias : myJson.categories,
                    cats : cat
                })
            })
    }

    isEmpty = () => {
        if (this.state.pname.length === 0 || this.state.pbrand.length === 0 || this.state.pdesc.length === 0 || this.state.pfoto.length === 0 || this.state.pprice.length === 0 || this.state.ptype.length === 0 || this.state.purl.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    uploadFotos = () => {  //FUNCIONA
        let photos = []
        for (var k = 0; k < this.state.pictures.length; k++) {
            let file = this.state.pictures[k];
            const storageref = firebase.storage().ref(`/products/${file.name}`);
            const task = storageref.put(file)
            task.on(firebase.storage.TaskEvent.STATE_CHANGED,
                {
                    'complete': () => {
                        storageref.getDownloadURL().then(url => {
                            photos.push(url)
                            if (photos.length === this.state.pictures.length ) {
                                console.log("---------------uploadFotos finished---------------")
                                this.setState({
                                    urls: photos
                                })
                                this.updateFotos(this.state.newId)
                            }
                        })
                    }
                }
            )

        }

    }

    updateFotos = id => {  //FUNCIONA
        console.log("------ UPDATE FOTOS BY ID ------")
        let data = {
            id: id,
            photos: this.state.urls
        }
        fetch("https://api-leia.herokuapp.com/product/update", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.dialogHandler("ready", "Guardado!")
                setTimeout(() => this.dialogHandler("none", ""), 700)
            })
            .catch(error => console.error('Error:', error));
    }

    addProduct = () => {  //FUNCIONA
        this.dialogHandler("loading", "Guardando...");
        let data = {
            brand: this.state.pbrand,
            name: this.state.pname,
            price: this.state.pprice,
            description: this.state.pdesc,
            photos: ".",
            status: "activo",
            url: this.state.purl,
            category : this.state.pcategory,
            subcategories : this.state.psubcategories,
            type: this.state.ptype
        }
        fetch("https://api-leia.herokuapp.com/product/add", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.setState({
                    newId: response.id
                })
                this.uploadFotos()
            })
            .catch(error => console.error('Error:', error));


    }

    handleNewProduct = e => {
        e.preventDefault()
        this.addProduct()
    }

    deleteProduct = e => { // FUNCIONA
        let idProduct = this.state.products[e.target.parentNode.parentNode.rowIndex - 1].id
        let data = {
            id: idProduct
        }
        fetch("https://api-leia.herokuapp.com/product/delete", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.getProducts()
            })
            .catch(error => console.error('Error:', error));
    }

    activarProducto = (e) => {  // FUNCIONA
        let idProduct = this.state.products[e.target.parentNode.parentNode.rowIndex - 1].id
        let data = {
            id: idProduct,
            status: "activo"
        }
        fetch("https://api-leia.herokuapp.com/product/update", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.getProducts()
            })
            .catch(error => console.error('Error:', error));
    }

    cancelAdd = () => {
    }

    handleInputBrand = i => this.setState({ pbrand: i.target.value })
    handleInputName = i => this.setState({ pname: i.target.value })
    handleInputPrice = i => this.setState({ pprice: i.target.value })
    handleInputDescription = i => this.setState({ pdesc: i.target.value })
    handleInputType = s => this.setState({ ptype: s.target.value })
    handleInputUrl = i => this.setState({ purl: i.target.value })
    handleNewCategory = selectedOptions => {
        this.setState({pcategory : selectedOptions.value })
        let inde;
        this.state.categorias.forEach(
            (c, index) => {
                if(c.name === selectedOptions.value){
                    inde = index
                }
            }
        )
        let subcats = []
        this.state.categorias[inde].subcategories.forEach(
            sub => subcats.push({value: sub.nombre, label:sub.nombre})
        )
        this.setState({
            subcats : subcats
        })
    }
    handleNewSubCategory = selectedOptions => this.setState({ psubcategories : selectedOptions.value });

    images = i => {
        if (this.state.pictures.length <= 0) {
            let photos = [];
            photos.push(i.target.files[0])
            this.setState({
                pictures: photos
            })
        }
        else {
            let photos = this.state.pictures
            photos.push(i.target.files[0])
            this.setState({
                pictures: photos
            })
        }
    }

    imagesUpdate = i => {
        if (this.state.productUpdate.id.length === 0) {
            alert("Por favor selecciona un producto para actualizar")
        } else {
            if (this.state.picturesUp.length <= 0) {
                let photos = [];
                photos.push(i.target.files[0])
                this.setState({
                    picturesUp: photos
                })
            }
            else {
                let photos = this.state.picturesUp
                photos.push(i.target.files[0])
                this.setState({
                    picturesUp: photos
                })
            }
        }
    }


    // ----- METHODS FOR UPDATE ------- //


    openSelectProductUpdate = () => {  // FUNCIONA
        this.setState({ dialogSelectProductUpdate: !this.state.dialogSelectProductUpdate })
    }


    selectProductUpdate = e => {  //FUNCIONA
        this.setState({
            productUpdate: {
                id: "", name: "", brand: "", descripción: "", price: "", type: "", url: "", photos: []
            }
        })
        let index = (e.target.parentNode.parentNode.rowIndex - 1);
        this.setState(
            {
                productUpdate: {
                    id: this.state.products[index].id,
                    name: this.state.products[index].name,
                    brand: this.state.products[index].brand,
                    description: this.state.products[index].description,
                    price: this.state.products[index].price,
                    type: this.state.products[index].type,
                    url: this.state.products[index].url,
                    photos: this.state.products[index].photos
                }
            }
        )
        this.openSelectProductUpdate();
    }

    handleUpdateProduct = (e) => { // FUNCIONA
        e.preventDefault()
        this.dialogHandler("loading", "Actualizando...")
        let data = {
            id: this.state.productUpdate.id,
            brand: this.state.productUpdate.brand,
            name: this.state.productUpdate.name,
            price: this.state.productUpdate.price,
            description: this.state.productUpdate.description,
            photos: this.state.productUpdate.photos,
            url: this.state.productUpdate.url,
            type: this.state.productUpdate.type
        }
        fetch("http://localhost:5000/product/update", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.dialogHandler("ready", "Actualizado!")
                setTimeout(() => this.dialogHandler("none", ""), 700)
            })
            .catch(error => console.error('Error:', error));
    }

    deleteImageForUpdate = e => { //FUNCIONA
        if (this.state.productUpdate.photos.length <= 5) {
            alert("El producto debe tener al menos 5 imágenes")
        } else {
            let index = (e.target.id);
            let photos = this.state.productUpdate.photos.splice(index, 1)
            console.log(photos)
            this.setState({
                productUpdate: {
                    ...this.state.productUpdate
                }
            })
        }

    }

    clickRef = () => {
        this.inputElement.click();
    }

    sustituteImageForUpdate = (e) => {
        //this.uploadFoto(e)
        this.setState({
            index: (e.target.id)
        })

        this.dialogUploadPhotoForUpdate()
    }

    dialogUploadPhotoForUpdate = () => { this.setState({ dialogUploadPhotoForUpdate: !this.state.dialogUploadPhotoForUpdate }) }

    uploadFoto = (e) => { // FUNCIONA
        let file = e.target.files[0]
        let urlfoto = ""
        const storageref = firebase.storage().ref(`/products/${file.name}`);
        const task = storageref.put(file)
        task.on(firebase.storage.TaskEvent.STATE_CHANGED,
            {
                'complete': () => {
                    storageref.getDownloadURL().then(url => {
                        urlfoto = url
                        let urls = this.state.productUpdate.photos
                        urls.push(urlfoto)
                        this.setState({
                            productUpdate: {
                                ...this.state.productUpdate, photos: urls
                            }
                        })
                    })

                }
            })
    }

    sustituteImageUpdated = (f) => {  // FUNCIONA
        let urlfoto = ""
        let index = this.state.index
        let file = f.target.files[0]
        const storageref = firebase.storage().ref(`/products/${file.name}`);
        const task = storageref.put(file)
        task.on(firebase.storage.TaskEvent.STATE_CHANGED,
            {
                'complete': () => {
                    storageref.getDownloadURL().then(url => {
                        urlfoto = url
                        let urls = this.state.productUpdate.photos
                        urls[index] = urlfoto
                        this.setState({
                            productUpdate: {
                                ...this.state.productUpdate, photos: urls
                            }
                        })
                        this.dialogUploadPhotoForUpdate()
                    })

                }
            })
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }


    render() {

        return (
            <div className="module productos-admin">
                <Sidebar type="admin" />
                <div className="module-container">
                    <section className="content">

                        <div className="header">
                            <img src={ofert} alt="icon" className="icon" />
                            <div className="title">
                                <h2>Administración de productos</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="main">
                            <nav className="tabsNav">
                                <ul>
                                    <li onClick={this.visVerTodos} className={"hvr-icon-float " + [this.state.visibleVerTodos ? "active" : "notActive"]}><span><FontAwesomeIcon icon={['fas', 'search']} className="hvr-icon" /></span>Ver todos</li>
                                    <li onClick={this.visAgregar} className={"hvr-icon-float " + [this.state.visibleAgregar ? "active" : "notActive"]}><span><FontAwesomeIcon icon={['fas', 'plus']} className="hvr-icon" /></span>Añadir producto</li>
                                    <li onClick={this.visActualizar} className={"hvr-icon-rotate " + [this.state.visibleActualizar ? "active" : "notActive"]}><span><FontAwesomeIcon icon={['fas', 'sync']} className="hvr-icon" /></span>Actualizar producto</li>
                                </ul>
                            </nav>


                            {
                                this.state.visibleVerTodos ?
                                    (
                                        <section className="section productos-section ver-todos">
                                            <div className="table-container eighty">
                                                <table className="products-table">
                                                    <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Nombre</th>
                                                        <th>Marca</th>
                                                        <th>Precio</th>
                                                        <th>Url</th>
                                                        <th>Tipo</th>
                                                        <th>Estatus</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.products.map(
                                                            p =>
                                                                <tr key={p.id}>
                                                                    <td>{p.id}</td>
                                                                    <td>{p.name}</td>
                                                                    <td>{p.brand}</td>
                                                                    <td>{p.price}</td>
                                                                    <td>{p.url}</td>
                                                                    <td>{p.type}</td>
                                                                    <td>{p.status}</td>
                                                                    <td> {p.status === "activo" ? <button onClick={this.deleteProduct}>Eliminar <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button> : <button onClick={this.activarProducto} className="btn-activar"> Activar <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span></button>} </td>
                                                                </tr>
                                                        )

                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    ) :
                                    null
                            }
                            {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
                            {
                                this.state.visibleAgregar ?
                                    (
                                        <section className="productos-section agregar">

                                            <div className="form-content">
                                                <form>
                                                    <div className="row">
                                                        <input placeholder="Ej. Huawei Mate X9" onChangeCapture={this.handleInputName} required />
                                                        <label>Nombre</label>
                                                    </div>
                                                    <div className="row">
                                                        <input placeholder="Ej. Huawei" onChangeCapture={this.handleInputBrand} required />
                                                        <label>Marca</label>
                                                    </div>
                                                    <div className="row">
                                                        <input placeholder="Ej. 12390" onChangeCapture={this.handleInputPrice} required />
                                                        <label>Precio</label>
                                                    </div>
                                                    <div className="row">
                                                        <select onChangeCapture={this.handleInputType} required defaultValue="Product">
                                                            <option value="Product">Producto</option>
                                                            <option value="Service">Servicio</option>
                                                        </select>
                                                        <label>Tipo</label>
                                                    </div>
                                                    <div className="row row-100">
                                                        <input placeholder="Ej. Celular con cámara de 30 MPX" onChangeCapture={this.handleInputDescription} required />
                                                        <label>Descripcion</label>
                                                    </div>
                                                    <div className="row row-100">
                                                        <input placeholder="Ej. www.youtube.com/huaweiMate30" onChangeCapture={this.handleInputUrl} required />
                                                        <label>Url</label>
                                                    </div>

                                                    <div className="row row-100 row-categorias">
                                                        <label>Categoría</label>
                                                        <Select options={this.state.cats}  onChange={this.handleNewCategory}  />
                                                    </div>

                                                    <div className="row row-100 row-categorias">
                                                        <label>Subcategorias</label>
                                                        <Select options={this.state.subcats}  onChange={this.handleNewSubCategory} disablec={this.state.pcategory.length === 0} />
                                                    </div>

                                                    <div className="row row-100">
                                                        <h3>Fotos</h3>
                                                    </div>

                                                    <div className="row">
                                                        <input type="file" accept="image/png, image/jpeg" onChange={this.images} required />
                                                        <label>Foto Principal</label>
                                                    </div>
                                                    <div className="row">
                                                        <input type="file" accept="image/png, image/jpeg" onChange={this.images} required />
                                                        <label>Foto 2</label>
                                                    </div>
                                                    <div className="row">
                                                        <input type="file" accept="image/png, image/jpeg" onChange={this.images} required />
                                                        <label>Foto 3</label>
                                                    </div>
                                                    <div className="row">
                                                        <input type="file" accept="image/png, image/jpeg" onChange={this.images} required />
                                                        <label>Foto 4</label>
                                                    </div>
                                                    <div className="row">
                                                        <input type="file" accept="image/png, image/jpeg" onChange={this.images} required />
                                                        <label>Foto 5</label>
                                                    </div>

                                                    <div className="row-buttons">
                                                        <button onClick={this.handleNewProduct} className="hvr-icon-float btn-save">Guardar <span><FontAwesomeIcon icon={['fas', 'save']} className="hvr-icon" /></span></button>
                                                        <button onClick={this.cancelAdd} className="hvr-icon-float btn-cancel">Cancelar <span><FontAwesomeIcon icon={['fas', 'ban']} className="hvr-icon" /></span></button>
                                                        <button onClick={this.limpiarCampos} className="hvr-icon-float btn-clean">Limpiar <span><FontAwesomeIcon icon={['fas', 'broom']} className="hvr-icon" /></span></button>
                                                    </div>

                                                </form>
                                            </div>



                                        </section>
                                    ) :
                                    (
                                        null
                                    )
                            }

                            {/*------------------------------------------------------------------------------------------------------------------------------------------*/}

                            {
                                this.state.visibleActualizar ?
                                    (
                                        <section className="productos-section actualizar">

                                            <div className="form-content">
                                                <button className="btn-seleccionar-producto" onClick={this.openSelectProductUpdate}>Seleccionar producto</button>
                                                <form>
                                                    <div className="row">
                                                        <input placeholder="Ej. Galaxy 10" onChangeCapture={this.handleUpInputName} value={this.state.productUpdate.name}></input>
                                                        <label>Nombre</label>
                                                    </div>
                                                    <div className="row">
                                                        <input placeholder="Ej. Samsung" onChangeCapture={this.handleUpInputBrand} value={this.state.productUpdate.brand}></input>
                                                        <label>Marca</label>
                                                    </div>
                                                    <div className="row">
                                                        <input placeholder="Ej. 10900" onChangeCapture={this.handleUpInputPrice} value={this.state.productUpdate.price}></input>
                                                        <label>Precio</label>
                                                    </div>
                                                    <div className="row">
                                                        <select onChangeCapture={this.handleUpInputType} required>
                                                            <option value="Product" selected={this.state.productUpdate.type === "Product" ? true : false}>Producto</option>
                                                            <option value="Service" selected={this.state.productUpdate.type === "Service" ? true : false}>Servicio</option>
                                                        </select>
                                                        <label>Tipo</label>
                                                    </div>
                                                    <div className="row row-100">
                                                        <input placeholder="Ej. Celular inteligente con doble cámara" onChangeCapture={this.handleUpInputDescription} value={this.state.productUpdate.description}></input>
                                                        <label>Descripcion</label>
                                                    </div>
                                                    <div className="row row-100">
                                                        <input placeholder="Ej. www.youtube.com/samsumg" onChangeCapture={this.handleUpInputUrl} value={this.state.productUpdate.url}></input>
                                                        <label>Url</label>
                                                    </div>
                                                    <div className="row row-100">
                                                        <h3>Fotos</h3>
                                                    </div>

                                                    {this.state.productUpdate.photos.length > 0 ?

                                                        (
                                                            <div className="row-images-updates">
                                                                {
                                                                    this.state.productUpdate.photos.map(
                                                                        (foto, index) =>

                                                                            <div className="row row-image-product-update" key={index}>
                                                                                <div>
                                                                                    <span className="span-delete-image-update" id={index} onClick={this.deleteImageForUpdate}>
                                                                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                                                                    </span>
                                                                                    <span className="span-sustitute-image-update" id={index} onClick={this.sustituteImageForUpdate}>
                                                                                        <FontAwesomeIcon icon={['fas', 'sync-alt']} />
                                                                                    </span>
                                                                                    <span className="span-watch-image-update">
                                                                                        <FontAwesomeIcon icon={['fas', 'search']} />
                                                                                    </span>
                                                                                </div>
                                                                                <img src={foto} alt={"foto"} />
                                                                            </div>



                                                                    )

                                                                }
                                                            </div>
                                                        ) : (null)
                                                    }

                                                    <div className="row">
                                                        <input id="image" type="file" accept="image/png, image/jpeg" onChange={this.uploadFoto} />
                                                        <label>Nueva Foto (opcional)</label>
                                                    </div>



                                                    <div className="row-buttons">
                                                        <button onClick={this.handleUpdateProduct} className="hvr-icon-float btn-save">Guardar <span><FontAwesomeIcon icon={['fas', 'save']} className="hvr-icon" /></span></button>
                                                        <button onClick={this.cancelUpdate} className="hvr-icon-float btn-cancel">Cancelar <span><FontAwesomeIcon icon={['fas', 'ban']} className="hvr-icon" /></span></button>
                                                        <button onClick={this.limpiarCamposUpdate} className="hvr-icon-float btn-clean">Limpiar <span><FontAwesomeIcon icon={['fas', 'broom']} className="hvr-icon" /></span></button>
                                                    </div>


                                                </form>
                                            </div>
                                        </section>
                                    ) :
                                    (
                                        null
                                    )
                            }

                            {/* ----------------- POP UP ELEMENT -----------------  */}

                            {
                                this.state.dialogSelectProductUpdate ?
                                    (
                                        <div className="popUp popUp-actualizar-producto">
                                            <div className="content">
                                                <span className={"close"} onClick={this.openSelectProductUpdate}><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                <div className="container">
                                                    <h2>Seleccionar un producto</h2>
                                                    <div className="popup-table table-container">
                                                        <table>
                                                            <thead>
                                                            <tr>
                                                                <th>id</th>
                                                                <th>nombre</th>
                                                                <th>marca</th>
                                                                <th>precio</th>
                                                                <th>url</th>
                                                                <th>tipo</th>
                                                                <th>estatus</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.products.map(
                                                                    p =>
                                                                        <tr key={p.id}>
                                                                            <td>{p.id}</td>
                                                                            <td>{p.name}</td>
                                                                            <td>{p.brand}</td>
                                                                            <td>{p.price}</td>
                                                                            <td>{p.url}</td>
                                                                            <td>{p.type}</td>
                                                                            <td>{p.status}</td>
                                                                            <td><button className="actualizar-select-btn" onClick={this.selectProductUpdate}>Seleccionar </button></td>
                                                                        </tr>
                                                                )

                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    (null)
                            }

                            {
                                this.state.dialogUploadPhotoForUpdate ?
                                    (
                                        <div className="popUp popUpUploadPhotoG">
                                            <div className="content">
                                                <span className="close" onClick={this.dialogUploadPhotoForUpdate}><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                <div className="container popUpUploadPhoto">

                                                    <h3>Actualizar la imagen {this.state.index}</h3>
                                                    <div className="select-area" onClick={this.clickRef}>
                                                        <p>Click aquí para seleccionar imagen</p>
                                                    </div>
                                                    <input type="file" onChange={this.sustituteImageUpdated} style={{ display: 'none' }} ref={input => this.inputElement = input}></input>

                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    (null)
                            }

                            {
                                this.state.dialogShowPhoto ?
                                    (
                                        <div className="popUp popUpShowPhoto">
                                            <div className="content">
                                                <span onClick={this.dialogShowPhoto}><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                <img src={this.state.productUpdate.photos[this.state.index]} alt={"Photo"} />
                                            </div>
                                        </div>
                                    ) :
                                    (null)
                            }

                        </div>

                    </section>
                </div>

                {
                    this.state.dialog.status ?
                        (
                            <StatelessDialog dialog={this.state.dialog}/>
                        ) : null
                }

            </div>
        );
    }
}

export default ProductosAdmin;