import React, {Component} from 'react';

class Categoria extends Component {

    constructor(props){
        super(props);
        this.state = {
            clicked : false
        }
    }

    render() {
        return (
            <li className={"subcategoria"}>
                 {this.props.sub.nombre} 
            </li>
        );
    }
}

export default Categoria;


