/* eslint-disable */
import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import Sidebar from "./../Sidebar/Sidebar"
import icon from "./../../Images/svg/girl.svg"
import firebase from "firebase";

import "./AdministracionUsuarios.scss"
import "./../styles/ComponentStyles.sass"
import "./../styles/PopUpSytyles.scss"
import "./../styles/hover.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class SadminNinjas extends Component {

    constructor() {
        super()
        this.state = {
            ninjas: [],
            ninjassSort: [],
            showAll: true,
            filterCardsByAlphabet: false,
            filterCardsByMen: false,
            filterCardsByWomen: false,
            filterCardsByName: false,
            filterCardsByStatus: false,
            nameFilter: "",
            dialogDelete: false,
            dialogNewNinja : false,
            toDelete: "",
            ninjaFoto : ""
        }
    }

    componentWillMount() {
        this.getNinjas()
    }

    filterCardsByName = () => {
        if (this.refs.filter_name.value.length > 0) {
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_alphabet.checked = false;
            this.refs.filter_status.checked = false
            this.filterCardsByMen();
            this.filterCardsByWomen();
            this.filterCardsByAlphabet();
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByName: true, nameFilter: this.refs.filter_name.value })
        }
        else { this.setState({ showAll: true, filterCardsByName: false, nameFilter: "" }) }
    }

    filterCardsByAlphabet = () => {
        if (this.refs.filter_alphabet.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByMen();
            this.filterCardsByWomen();
            this.filterCardsByStatus()
            let sorted = this.state.ninjas.sort(function (o1, o2) {
                if (o1.name > o2.nane) { return 1; }
                else if (o1.name < o2.name) { return -1; }
                return 0;
            });
            this.setState({ showAll: false, ninjassSort: sorted, filterCardsByAlphabet: true })
        } else {
            this.getNinjas()
            this.setState({ filterCardsByAlphabet: false, showAll: true, ninjassSort: [] })
        }
    }
    filterCardsByMen = () => {
        if (this.refs.filter_men.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_women.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByWomen()
            this.filterCardsByAlphabet()
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByMen: true })
        } else {
            this.setState({ filterCardsByMen: false, showAll: true })
        }
    }

    filterCardsByWomen = () => {
        if (this.refs.filter_women.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByMen()
            this.filterCardsByAlphabet()
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByWomen: true })
        } else {
            this.setState({ filterCardsByWomen: false, showAll: true })
        }
    }
    filterCardsByStatus = () => {
        if (this.refs.filter_status.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_women.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_men.checked = false
            this.filterCardsByName()
            this.filterCardsByWomen()
            this.filterCardsByMen()
            this.filterCardsByAlphabet()
            this.setState({ showAll: false, filterCardsByStatus: true })
        } else {
            this.setState({ filterCardsByStatus: false, showAll: true })
        }
    }

    restoreFilters = () => {
        if (this.refs.filter_alphabet.checked || this.refs.filter_men.checked || this.refs.filter_women.checked || this.refs.filter_status.checked || this.refs.filter_name.value.length > 0) {
            this.refs.filter_alphabet.checked = false;
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_status.checked = false;
            this.refs.filter_name.value = "";
            this.filterCardsByAlphabet();
            this.filterCardsByName();
            this.filterCardsByWomen();
            this.filterCardsByMen();
            this.filterCardsByStatus();
        } else {
            alert("No hay ningún filtro aplicado")
        }
    }

    getNinjas = () => {
        fetch("https://api-leia.herokuapp.com/user/getAllAdminUsers").then(response => response.json()).then((myJson) => this.setState({ ninjas: myJson.adminUsers }))
    }

    handleDelete = (e) => {
        this.setState({
            toDelete: e.target.id
        })
        this.dialogDelete()
    }

    deleteById = () => {
        let data = {
            id: this.state.toDelete
        }
        fetch("https://api-leia.herokuapp.com/user/deleteUser", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.getNinjas()
                this.dialogDelete()
            })
            .catch(error => console.error('Error:', error));
    }

    dialogDelete = () => {
        this.setState({
            dialogDelete: !this.state.dialogDelete
        })
    }

    reactivarNinja = (e) => {
        let data = {
            id: e.target.id,
            status: "activo"
        }
        fetch("https://api-leia.herokuapp.com/user/updateUser", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.getNinjas()
            })
            .catch(error => console.error('Error:', error));
    }

    dialogNewNinja = () => {
        this.setState({
            dialogNewNinja : !this.state.dialogNewNinja
        })
    }

    addUserAdmin = (e) => {
        e.preventDefault()
        if(this.state.ninjaFoto.length > 0 ){
            let data = {
                name: this.refs.newn_name.value,
                firstName: this.refs.newn_fname.value,
                secondName: this.refs.newn_sname.value,
                birthDate: this.refs.newn_bdate.value,
                email: this.refs.newn_email.value,
                phoneNumber: this.refs.newn_phone.value,
                address: this.refs.newn_address.value,
                gender: this.gender.value,
                photo: this.state.ninjaFoto,
                code: this.refs.newn_cp.value,
                username: this.refs.newn_uname.value,
                status: "activo",
                password: this.refs.newn_password.value,
            }
            fetch("https://api-leia.herokuapp.com/user/new-user", {
                method: 'POST',
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then(response => {
                    console.log('Success:', response)
                    let dat = { id: response.id, role: "admin" }
                    fetch("https://api-leia.herokuapp.com/user/updateUser", {
                        method: 'POST',
                        body: JSON.stringify(dat), // data can be `string` or {object}!
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(res => res.json())
                        .then(response => {
                            console.log('Success:', response)
                            this.getNinjas()
                        })
                        .catch(error => console.error('Error:', error));
                })
                .catch(error => console.error('Error:', error));
        } else {
            alert("Espera unos segundos más en lo que la foto es procesada")
        }
    }

    addActive = e => {
        e.target.classList.add("active")
    }

    analizeInput = (e) => {
        if(e.target.value.length > 0 ) {
            e.target.classList.add("active")
        }
        else {
            e.target.classList.remove("active")
        }
    }

    uploadImageNinja = (f) => {
        let file = f.target.files[0]
        const storageref = firebase.storage().ref(`/products/${file.name}`);
        const task = storageref.put(file)
        task.on(firebase.storage.TaskEvent.STATE_CHANGED,
            {
                'complete': () => {
                    storageref.getDownloadURL().then(url => {
                        this.setState({
                            ninjaFoto : url
                        })
                    })

                }
            })
    }



    render() {
        if(localStorage.getItem('user-role')==="cliente"){
            return (
                <div className="module admin-clientes">
                    <Sidebar type="sadmin" />
                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={icon} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de usuarios admin</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">
                                <div className="searchfield">
                                    <input type="text" placeholder="buscar administrador" onChange={this.filterCardsByName} ref="filter_name" />
                                    <div><label><span><FontAwesomeIcon icon={['fas', 'search']} /></span></label></div>
                                </div>
                                <div className="filter-row">
                                    <p className="title">filtros</p>
                                    <div className="options">
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByAlphabet} ref="filter_alphabet" />
                                            <label> A - Z </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByMen} ref="filter_men" />
                                            <label> Hombres </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByWomen} ref="filter_women" />
                                            <label> Mujeres </label>
                                        </div>
                                        <div className="option" style={{ display: 'none' }}>
                                            <input type="checkbox" onChange={this.filterCardsByStatus} ref="filter_status" />
                                            <label> Activos </label>
                                        </div>
                                        <div className="option option-no-border">
                                            <button onClick={this.restoreFilters}>restaurar</button>
                                        </div>
                                        <div className="option option-no-border">
                                            <button className="new-ninja" onClick={this.dialogNewNinja}> <FontAwesomeIcon icon={['fas', 'plus']} /> agregar nuevo </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="admin-client-cards">
                                    {
                                        this.state.showAll ?
                                            (
                                                this.state.ninjas.map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByName ?
                                            (
                                                this.state.ninjas.filter(
                                                    ninja => (ninja.name + " " + ninja.firstName + " " + ninja.secondName).includes(this.state.nameFilter)
                                                ).map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByAlphabet ?
                                            (
                                                this.state.ninjassSort.map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByMen ?
                                            (
                                                this.state.ninjas.filter(
                                                    ninja => ninja.gender.includes("Hombre")
                                                ).map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByWomen ?
                                            (
                                                this.state.ninjas.filter(
                                                    ninja => ninja.gender.includes("Mujer")
                                                ).map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByStatus ?
                                            (
                                                this.state.ninjas.filter(
                                                    ninja => ninja.status.includes("activo")
                                                ).map(
                                                    ninja =>
                                                        <div key={ninja.id} className="card">
                                                            <p className="tipo">{ninja.role} {ninja.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={ninja.photo} alt="" />
                                                                <div><p className="nombre">{ninja.name + " " + ninja.firstName + " " + ninja.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {ninja.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {ninja.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {ninja.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {ninja.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + ninja.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {ninja.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarNinja} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={ninja.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.dialogDelete ?
                                            (
                                                <div className="popUp popUp-Delete-Client">
                                                    <div className="content">
                                                        <div className="container">
                                                            <h2>¿Estás seguro que quieres eliminar a este cliente?</h2>
                                                            <p>Esta acción no se puede deshacer</p>
                                                            <div className="btns-container">
                                                                <button className="btn-delete" onClick={this.deleteById}> <FontAwesomeIcon icon={['fas', 'times']} /> Sí, eliminar</button>
                                                                <button className="btn-cancel" onClick={this.dialogDelete}>No, fue un error</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (null)
                                    }

                                    {
                                        this.state.dialogNewNinja ?
                                            (
                                                <div className="popUp popUp-new-ninja">
                                                    <div className="content">
                                                        <span className="close" onClick={this.dialogNewNinja}><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                        <div className="container">
                                                            <h2>Agregar un administrador</h2>


                                                            <form className="inputs">
                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_name" id="newn-name" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                                                        <label htmlFor="newn-name"> Nombre </label>
                                                                    </div>

                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_fname" id="newn-fname" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                                                        <label htmlFor="newn-fname"> Primer apellido </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_sname" id="newn-sname" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                                                        <label htmlFor="newn-sname"> Segundo apellido </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_bdate" id="newn-bdate" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'calendar-alt']} /> </span>
                                                                        <label htmlFor="newn-bdate"> Fecha de nacimiento </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_email" id="newn-email" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'envelope']} /> </span>
                                                                        <label htmlFor="newn-email"> Email </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="number" ref="newn_phone" id="newn-phone" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'phone']} /> </span>
                                                                        <label htmlFor="newn-phone"> Teléfono </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_address" id="newn-address" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> </span>
                                                                        <label htmlFor="newn-address"> Dirección </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_cp" id="newn-cp" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'map-marker-alt']} /> </span>
                                                                        <label htmlFor="newn-cp"> CP </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="text" ref="newn_uname" id="newn-uname" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                                                        <label htmlFor="newn-uname"> Nombre de usuario </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="password" ref="newn_password" id="newn-password" onChange={this.analizeInput} onClick={this.addActive}  required ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'key']} /> </span>
                                                                        <label htmlFor="newn-password"> Password </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <select ref={(input) => this.gender = input} required  defaultValue="Mujer" >
                                                                            <option value="Hombre">Hombre</option>
                                                                            <option value="Mujer">Mujer</option>
                                                                        </select>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                                                    </div>
                                                                    <div className="label-title"> <label> Género </label> </div>
                                                                </div>

                                                                <div className="input-row-ninja">
                                                                    <div className="input-div">
                                                                        <input type="file" ref="newn_photo" id="newn-photo" accept="image/*"  required onChange={this.uploadImageNinja} ></input>
                                                                        <span> <FontAwesomeIcon icon={['fas', 'key']} /> </span>
                                                                        <label htmlFor="newn-photo"> Password </label>
                                                                    </div>
                                                                </div>

                                                                <div className="input-row-ninja input-row-ninja-buttons">
                                                                    <button className="btn-save" onClick={this.addUserAdmin}> <FontAwesomeIcon icon={['fas', 'user-ninja']} />  Agregar admin  </button>
                                                                    <button className="btn-cancel" onClick={this.dialogNewNinja}> <FontAwesomeIcon icon={['fas', 'ban']} />  Cancelar  </button>
                                                                </div>



                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (null)
                                    }

                                </div>

                            </div>

                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to={"/not-page"}/>
            )
        }
    }
}

export default SadminNinjas;
