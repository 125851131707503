import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Carrousel extends Component {
    render() {
        return (
            <div className={"carousel"}>
                <img src="https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/Marketplace%2FBanners%2FES_1190x420.jpg?alt=media&token=a455185e-6e1d-46b3-9e22-6a70374224ca" />
            </div>
        );
    }
}

export default Carrousel;