import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import ofert from "../../Images/svg/delivery-truck.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Servicio from "../AdminCotizador/Servicio/Servicio";
import PedidoAdmin from "./PedidoAdmin/PedidoAdmin";

class AdminPedidos extends Component {

    constructor(props){
        super(props)
        this.state = {
            pedidos : []
        }
    }

    componentWillMount() {
        //this.getPedidos()
    }

    getPedidos = () => {

    }

    setCompletado = pedido => {
        let data = {
            id : pedido.id
            //resto de los atributos
        }
    }

    setPendiente = pedido => {
        let data = {
            id : pedido.id
            //resto de los atributos
        }
    }


    render() {
        return (
            <div className={"module admin-pedidos"}>
                <Sidebar type="admin" />

                <div className="module-container">
                    <section className="content">

                        <div className="header">
                            <img src={ofert} alt="icon" className="icon" />
                            <div className="title">
                                <h2>Administración de pedidos</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="main">

                            <div className="title-pedidos">
                                <h2>Todos los pedidos</h2>
                            </div>

                            <div className="table-list-pedidos">
                                {/*
                                    this.state.pedidos.map (
                                        pedido => <PedidoAdmin pedido={pedido} setCompletado={this.setCompletado} setPendiente={this.setPendiente} />
                                    )
                                */}
                            </div>

                        </div>

                    </section>
                </div>

            </div>
        );
    }
}

export default AdminPedidos;