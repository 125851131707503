import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import {Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ofert from "../../Images/svg/videogame.svg";
import "./../styles/TableList.scss"
import "./CategoriasModule.scss"
import Categoria from "./Categoria";
import "./../styles/PopUpSytyles.scss"
import Dialog from "../Dialog/Dialog";

class CategoriasSadmin extends Component {

    constructor(){
        super()
        this.state = {
            categorias : [],
            category : [],
            showDialog : false,
            dialog : { type: "add-categoria", title : "Añadir una categoría" },
            newCat : {nombre : "", subcategorias: []}
        }
    }

    componentWillMount() {
       this.getCategorias()
    }

    getCategorias = () => {
        fetch("https://api-leia.herokuapp.com/category/getAll")
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        categorias: myJson.categories
                    }
                )
            })
    }

    toggleDialog = () => {
        if(this.state.showDialog === true ){
            this.getCategorias()
            this.setState({ showDialog : false})
        } else {
            this.setState({showDialog : true })
        }
    }

    handleNewCategoria = () => {
        this.setState({
            dialog : { type: "add-categoria", title : "Añadir una categoría" }
        })
        this.toggleDialog()
    }

    newCategory = (category) => {
        console.log(category)
        let data = {
            svg : category.svg,
            name : category.nombre,
            subcategories : category.subcategorias,
            selected : false
        }
        fetch("https://api-leia.herokuapp.com/category/add", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.toggleDialog()
            })
            .catch(error => console.error('Error:', error));
    }




    render() {
        if(localStorage.getItem('user-role') === "cliente"){
            return (
                <div className={"module module-categorias"}>
                    <Sidebar type="sadmin" />

                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={ofert} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de categorías</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">

                                <div className="title-categorias">
                                    <h2>Todas las categorías</h2>
                                    <button onClick={this.handleNewCategoria}> <FontAwesomeIcon icon={['fas', 'plus']} /> Agregar categoría</button>
                                </div>

                                <div className="table-list">

                                    {
                                        this.state.categorias.map(
                                            categoria => <Categoria categoria={categoria} key={Math.random()}  delete={this.handleDelete} update={this.handleUpdate} />
                                        )
                                    }

                                </div>

                            </div>

                        </section>
                    </div>


                    {
                        this.state.showDialog ?
                            (
                                <Dialog dialog={this.state.dialog} closer={this.toggleDialog} handler={this.newCategory}  />

                            ) : null
                    }



                </div>
            );
        } else {
            return (
                <Redirect to={"/sorry-not-sorry"} />
            )
        }
    }
}

export default CategoriasSadmin;


/*
Electrónica
Smartphones
Laptops
Pcs
Smartwatch
Tablets
Smart TV
Lentes RV
Auriculares
Scooters eléctricos
Cámaras
Mouse, teclados
Monitores
Impresoras 3D
Microcontroladores
*
*
* */