import React, { Component } from 'react';

import Batalla from "./../../img/photos/batalla.png"
import Ezequiel from "./../../img/photos/ezequiel.png"
import Isidro from "./../../img/photos/isidro.jpg"
import Ivan from "./../../img/photos/ivan.png"
import Jorge from "./../../img/photos/jorge.png"
import Lemus from "./../../img/photos/lemus.png"
import Ruben from "./../../img/photos/ruben_redondo.jpg"
import Alvaro from "./../../img/photos/alvaro.jpg"
import IvanG from "./../../img/photos/ivang.jpeg"
import Valentin from "./../../img/photos/valentin.jpeg"
import Miguel from "./../../img/photos/miguel.jpeg"
import Schabiby from "./../../img/photos/schabiby.jpeg"
import Julio from "./../../img/photos/julio.jpg"
import Abigail from "./../../img/photos/abigail.jpeg"
import "./Photos.css"

class Photo extends Component {
    render() {
        const photos = [
            {
                nombre: "Abigail Aguilar",
                foto: Abigail,
                puesto: "Healthcare Analyst",
                key : 1
            },
            {
                nombre: "Schabiby Dorcelus",
                foto: Schabiby,
                puesto: "UI/UX Developer Jr",
                key : 2
            },
            {
                nombre: "Isidro Aguilar",
                foto: Isidro,
                puesto: "Chief Executive Officer",
                key: 3
            },
            {
                nombre: "Ezequiel Delgado",
                foto: Ezequiel ,
                puesto: "Chief Legal Officer",
                key: 4
            },
            {
                nombre: "Jorge Ramírez",
                foto: Jorge ,
                puesto: "Full Stack Developer",
                key: 5
            },
            {
                nombre: "Alejandro Lemus",
                foto: Lemus,
                puesto: "Full Stack Developer Senior",
                key: 6
            },
            {
                nombre: "Rubén Redondo",
                foto: Ruben,
                puesto: "Full Stack Developer",
                key: 7
            },
            {
                nombre: "Iván Rodríguez",
                foto: Ivan,
                puesto: "Full Stack Developer",
                key: 8
            },
            {
                nombre: "Alvaro Torres",
                foto: Alvaro,
                puesto: "Innovation Coordinator",
                key: 9
            },
            {
                nombre: "Jesus Batalla",
                foto: Batalla,
                puesto: "Sales Engineer",
                key: 10
            },
            {
                nombre: "Ivan Guerrero",
                foto: IvanG,
                puesto: "Full Stack Developer Senior",
                key: 11
            },
            {
                nombre: "Julio Chavez",
                foto: Julio,
                puesto: "Infrastructure Support Engineer",
                key: 12
            },
            {
                nombre: "Valentin Alejandre",
                foto: Valentin,
                puesto: "Client Attention Service",
                key: 13
            },
            {
                nombre: "Miguel Molina",
                foto: Miguel,
                puesto: "Full Stack Developer Jr",
                key: 14
            }
        ]
        return(
            <div className="photos">
                {
                    photos.map(
                        photo =>   
                        <div className="photo" key={photo.key}>
                            <img src={photo.foto} alt={photo.nombre}/>
                            <h4>{photo.nombre}</h4>
                            <p>{photo.puesto}</p>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Photo;