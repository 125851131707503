import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./Webcard.css"

class Webcard extends Component {
    render() {
        return(
            <div className="webcard">
            
                <div className="webcard-image">
                        {
                            this.props.imageType === "icon" ?  (
                                <FontAwesomeIcon icon={this.props.icon}/>
                            ) : (
                                <img  src={this.props.image} alt=""/>
                            )
                        }
                </div>

                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>
            </div>
        )
    }
}

export default Webcard;