import React, {Component} from 'react';
import "./SidePay.scss";
import SidePayCard from "./SidePayCard/SidePayCard";
import Paypal from "./../../../../Images/svg/paypal.svg";
import Card from "./../../../../Images/svg/credit-card.svg";
import {Redirect} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StatelessDialog from "../../../StatelessDialog/StatelessDialog";

class SidePay extends Component {

    constructor(props){
        super(props)
        this.state = {
            clicked : false,
            clicked1 : false,
            redirect  : false,
            redirectUrl : "",
        }
    }

    handleClick = (e) => {
        this.setState( {
            clicked : true,
            clicked1 : false
        })
    }
    handleClick1 = () => {
        this.setState({
            clicked1 : true,
            clicked : false
        })
    }

    createPaypal = () => {
        let items = []
        this.props.productos.forEach( p => items.push( {
                    name : p.name,
                    quantity : p.cantity,
                    price : p.price,
                    currency : 'MXN'
                } ))
        let data = {
            items : items,
            total : this.props.total
        }
        console.log(data)
        fetch("http://localhost:5000/payment/paypal/create", {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                this.setState( {
                    redirectUrl : response.redirect
                })
                localStorage.setItem('totalPayment', response.total);
                window.open(this.state.redirectUrl, "_self", "location=no, menubar=no, titlebar=no, directories=no", true )
            })
            .catch(error => console.error('Error:', error));
    }


    createTarjeta = () => {
        this.props.dialogHandler("loading", "Efectuando pago")
        let items = []
        this.props.productos.forEach(
            producto => items.push(
                {
                    name : producto.name ,
                    description : producto.description,
                    unit_price : producto.price,
                    quantity : producto.cantity,
                    tags : [producto.subcategories],
                    type : "physical",
                }
            )
        )
        let data = {
            customer_id : localStorage.getItem('userId'),
            line_items : items,
        }
        console.log(data)

        fetch("http://localhost:5000/payment/conekta/create", {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log(response)
                if(response.results._id){
                    console.log("success")
                    this.createConektaPayment(response.results._id)
                }
            })
            .catch(error => {
                console.error('Error:', error)
                this.props.dialogHandler("error", "Ocurrió un error :(\nInténtalo más tarde")
            });

    }

    createConektaPayment = id => {
        fetch("http://localhost:5000/payment/conekta/createCharge", {
            method: 'POST',
            body: JSON.stringify({order_id : id }),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log(response.charge.payment_method.barcode_url);
                localStorage.setItem('carrito', "");
                this.props.dialogHandler("none", "");
                this.props.dialogPaymentHandler("ready", "Referencia de pago lista!", "Revisa tu correo electrónico y descarga el código de barras para pagar en OXOO", response.charge.payment_method.barcode_url )
            })
            .catch(error => {
                console.error('Error:', error)
                this.props.dialogHandler("error", "Ocurrió un error :(\nInténtalo más tarde")
            });
    }


    render() {
            return (
                <div className={"side-pay"}>

                    <div className="step first">
                        <div className="progress">
                            <span className={"selected"}> <FontAwesomeIcon icon={['fas', 'circle']} /> </span>
                            <span> <FontAwesomeIcon icon={['fas', 'circle']} /> </span>
                            <span> <FontAwesomeIcon icon={['fas', 'circle']} /> </span>
                        </div>
                        <div className="content">
                            <h2>Seleccionar método de pago</h2>
                            <div className="options">
                                <SidePayCard title={"Pago electrónico con"} svg={Paypal} clicked={this.state.clicked}  handleClick={this.handleClick}/>
                                <SidePayCard title={"Pago con tarjeta o establecimiento"} svg={Card} clicked={this.state.clicked1} handleClick={this.handleClick1} />
                            </div>
                        </div>
                        <div className="button">
                            <button onClick={ this.state.clicked ? this.createPaypal  : this.createTarjeta }>
                                Continuar
                            </button>
                        </div>
                    </div>



                </div>
            )
        }
}

export default SidePay;