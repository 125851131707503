import app from 'firebase/app';


var config = {
    apiKey: "AIzaSyA6tFXsi6Dii8K3lbOu5rPAo5VyOvb1-hQ",
    authDomain: "leia-mx.firebaseapp.com",
    databaseURL: "https://leia-mx.firebaseio.com",
    projectId: "leia-mx",
    storageBucket: "leia-mx.appspot.com",
    messagingSenderId: "254320358792",
    appId: "1:254320358792:web:af9f5501be853499"
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);


}

export default Firebase;