import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Wishlist.scss"
import WishListProduct from "./WishListProduct/WishListProduct";
import StatelessDialog from "../StatelessDialog/StatelessDialog";

class Wishlist extends Component {

    constructor(){
        super()
        this.state = {
            idUser : localStorage.getItem('userId'),
            products : [],
            dialog : { status : false, info : {}}
        }
    }

    componentWillMount() {
        this.getWishList();
    }

    getWishList = () => {

       let data = {
           id : this.state.idUser
       };
        fetch("https://api-leia.herokuapp.com/user/wishList/getAll", {
            method: 'POST',
            body : JSON.stringify(data) ,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                this.setState({
                    products : response
                })
            })
            .catch(error => console.error('Error:', error));
    };

    delete = (e) => {
        this.dialogHandler("loading", "Eliminando")
        let data = {
            id : localStorage.getItem('userId'),
            product_inListId : e.target.id
        };
        console.log(data)
        fetch("https://api-leia.herokuapp.com/user/wishList/delete", {
            method: 'POST',
            body : JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.getWishList()
                setTimeout( () => {
                    this.dialogHandler("ready", "Producto eliminado!")
                }, 0 )
                setTimeout( () => {
                    this.dialogHandler("none", "")
                }, 700 )
            })
            .catch(error => {
                console.error('Error:', error)
                this.dialogHandler("error", "Ocurrió un error")
            });
    };

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }


    render() {
        return (
            <div className={"module wishlist"}>
                <Sidebar type={"cliente"} />

                <div className="module-container-wishlist">
                    <section className="content-wishlist">
                        <div className="header">
                            <h2>  <FontAwesomeIcon icon={['fas', 'store']} />  Wishlist </h2>
                        </div>

                        {
                            this.state.products.length > 0 ?
                                (
                                    <div className={"main-wishlist"}>
                                        <div className="container-wishlist">
                                            <div className={"products"}>
                                                <h2>Productos</h2>
                                                {
                                                    this.state.products.map (
                                                        p => <WishListProduct product={p} delete={this.delete} key={Math.random()} dialogHandler={this.dialogHandler} />

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div className={"no-products"}>
                                        <span><FontAwesomeIcon icon={['far', 'frown-open']} /></span>
                                        <h2>No tienes productos en tu wishlist</h2>
                                        <p>Da click en el <FontAwesomeIcon icon={['fas', 'heart']} /> de un producto del marketplace para agregarlo a tu wishlist  </p>
                                    </div>
                                )
                        }
                    </section>
                </div>

                {
                    this.state.dialog.status ? <StatelessDialog dialog={this.state.dialog}/> : null
                }

            </div>
        );
    }
}

export default Wishlist;


