import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./Button.css"

class Button extends Component {
    render() {

        return(
            <button className={this.props.color} onClick={this.props.handler}>
                {this.props.text}
                <span>
                    <FontAwesomeIcon icon={this.props.icon} />
                </span>
            </button>
        )
        
    }
}

export default Button