import React, { Component } from 'react';

import Button from "./../Button/Button"
import logo from "./../../img/logo.png"
import "./Cover.css"

class Cover extends Component {


    goToContact = () => {
        window.location.href="#contacto"
    }

    render() {
        return(
            <section className="cover">
                <div className="container">
                    <img src={logo} alt=""/>
                    <h2 className="cover-slogan">Desarrollando el futuro</h2>
                    <p className="cover-description">
                        Desarrollamos proyectos tecnológicos, <br />empresariales y de marketing a la medida.
                    </p>
                    <Button color="btnGreen" text="¡Contáctanos!" handler={this.goToContact}/>
                </div>
            </section>
        )
    }
}

export default Cover;