/* eslint-disable */
import React, { Component } from 'react';
import Sidebar from "./../Sidebar/Sidebar"
import {Redirect} from "react-router-dom";
import icon from "./../../Images/svg/girl.svg"

import "./AdministracionClientes.scss"
import "./../styles/ComponentStyles.sass"
import "./../styles/PopUpSytyles.scss"
import "./../styles/hover.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class SadminClientes extends Component {

    constructor() {
        super()
        this.state = {
            clients: [],
            clientsSort: [],
            showAll: true,
            filterCardsByAlphabet: false,
            filterCardsByMen: false,
            filterCardsByWomen: false,
            filterCardsByName: false,
            filterCardsByStatus : false,
            nameFilter: "",
            dialogDelete : false,
            toDelete : ""
        }
    }

    componentWillMount() {
        this.getClients()
    }

    filterCardsByName = () => {
        if (this.refs.filter_name.value.length > 0) {
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_alphabet.checked = false;
            this.refs.filter_status.checked = false
            this.filterCardsByMen();
            this.filterCardsByWomen();
            this.filterCardsByAlphabet();
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByName: true, nameFilter: this.refs.filter_name.value })
        }
        else { this.setState({ showAll: true, filterCardsByName: false, nameFilter: "" }) }
    }

    filterCardsByAlphabet = () => {
        if (this.refs.filter_alphabet.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByMen();
            this.filterCardsByWomen();
            this.filterCardsByStatus()
            let sorted = this.state.clients.sort(function (o1, o2) {
                if (o1.name > o2.nane) { return 1; }
                else if (o1.name < o2.name) { return -1; }
                return 0;
            });
            this.setState({ showAll: false, clientsSort: sorted, filterCardsByAlphabet: true })
        } else {
            this.getClients()
            this.setState({ filterCardsByAlphabet: false, showAll: true, clientsSort: [] })
        }
    }
    filterCardsByMen = () => {
        if (this.refs.filter_men.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_women.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByWomen()
            this.filterCardsByAlphabet()
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByMen: true })
        } else {
            this.setState({ filterCardsByMen: false, showAll: true })
        }
    }

    filterCardsByWomen = () => {
        if (this.refs.filter_women.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_status.checked = false
            this.filterCardsByName()
            this.filterCardsByMen()
            this.filterCardsByAlphabet()
            this.filterCardsByStatus()
            this.setState({ showAll: false, filterCardsByWomen: true })
        } else {
            this.setState({ filterCardsByWomen: false, showAll: true })
        }
    }
    filterCardsByStatus = () => {
        if (this.refs.filter_status.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_women.checked = false
            this.refs.filter_alphabet.checked = false
            this.refs.filter_men.checked = false
            this.filterCardsByName()
            this.filterCardsByWomen()
            this.filterCardsByMen()
            this.filterCardsByAlphabet()
            this.setState({ showAll: false, filterCardsByStatus: true })
        } else {
            this.setState({ filterCardsByStatus: false, showAll: true })
        }
    }

    restoreFilters = () => {
        if (this.refs.filter_alphabet.checked || this.refs.filter_men.checked || this.refs.filter_women.checked || this.refs.filter_status.checked || this.refs.filter_name.value.length > 0) {
            this.refs.filter_alphabet.checked = false;
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_status.checked = false;
            this.refs.filter_name.value = "";
            this.filterCardsByAlphabet();
            this.filterCardsByName();
            this.filterCardsByWomen();
            this.filterCardsByMen();
            this.filterCardsByStatus();
        } else {
            alert("No hay ningún filtro aplicado")
        }
    }

    getClients = () => {
        fetch("https://api-leia.herokuapp.com/user/getAll").then(response => response.json()).then((myJson) => this.setState({ clients: myJson.users }))
    }

    handleDelete = (e) => {
        this.setState({
            toDelete : e.target.id
        })
        this.dialogDelete()
    }

    deleteById = () => {
        let data = {
            id: this.state.toDelete
        }
        fetch("https://api-leia.herokuapp.com/user/deleteUser", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.getClients()
                this.dialogDelete()
            })
            .catch(error => console.error('Error:', error));
    }

    dialogDelete = () => {
        this.setState({
            dialogDelete : !this.state.dialogDelete
        })
    }

    reactivarCliente = (e) => {
        let data = {
            id: e.target.id,
            status : "activo"
        }
        fetch("https://api-leia.herokuapp.com/user/updateUser", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.getClients()
            })
            .catch(error => console.error('Error:', error));
    }



    addUser = () => {
        let data = {
            name: "Ninja",
            firstName: "Rodriguez",
            secondName: "García",
            birthDate: "22/12/1998",
            email: "ivanninja@gmail.com",
            phoneNumber: "4773947583",
            address: "Blvd. Avenue 54368",
            gender: "Hombre",
            photo: "https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/cef31105-8a6e-4211-a74b-2f0bbd9791fb-profile_image-300x300.png?alt=media&token=6fa2738c-be50-4159-94dd-c0ad1ad138cc",
            code: "56728",
            username: "ninja",
            status : "activo",
            password: "ninja",
        }
        fetch("https://api-leia.herokuapp.com/user/new-user", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error));
    }



    render() {
        if(localStorage.getItem('user-role') === "sadmin"){
            return (
                <div className="module admin-clientes">
                    <Sidebar type="sadmin" />
                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={icon} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de clientes</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">
                                <div className="searchfield">
                                    <input type="text" placeholder="buscar cliente" onChange={this.filterCardsByName} ref="filter_name" />
                                    <div><label><span><FontAwesomeIcon icon={['fas', 'search']} /></span></label></div>
                                </div>
                                <div className="filter-row">
                                    <p className="title">filtros</p>
                                    <div className="options">
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByAlphabet} ref="filter_alphabet" />
                                            <label> A - Z </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByMen} ref="filter_men" />
                                            <label> Hombres </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByWomen} ref="filter_women" />
                                            <label> Mujeres </label>
                                        </div>
                                        <div className="option" style={{display:'none'}}>
                                            <input type="checkbox" onChange={this.filterCardsByStatus} ref="filter_status" />
                                            <label> Activos </label>
                                        </div>
                                        <div className="option option-no-border">
                                            <button onClick={this.addUser}>restaurar</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="admin-client-cards">
                                    {
                                        this.state.showAll ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.role === "cliente"
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )

                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByName ?
                                            (

                                                this.state.clients.filter(
                                                    client => client.role === "cliente"
                                                ).filter(
                                                    client => (client.name + " " + client.firstName + " " + client.secondName).includes(this.state.nameFilter)
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )



                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByAlphabet ?
                                            (
                                                this.state.clientsSort.filter(
                                                    client => client.role === "cliente"
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByMen ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.role === "cliente"
                                                ).filter(
                                                    client => client.gender.includes("Hombre")
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByWomen ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.role === "client"
                                                ).filter(
                                                    client => client.gender.includes("Mujer")
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByStatus ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.role === "cliente"
                                                ).filter(
                                                    client => client.status.includes("activo")
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role} {client.status === "inactivo" ? <span title="inactivo" className="no"> <FontAwesomeIcon icon={['fas', 'circle']} /> </span> : <span className="si" title="activo" ><FontAwesomeIcon icon={['fas', 'circle']} /></span>} </p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                                {client.status === "inactivo" ? <a className="btn-reactivar" onClick={this.reactivarCliente} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span> Activar </a> : <a className="btn-delete" onClick={this.handleDelete} id={client.id}> <span><FontAwesomeIcon icon={['fas', 'times']} /></span> Eliminar </a>}
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.dialogDelete ?
                                            (
                                                <div className="popUp popUp-Delete-Client">
                                                    <div className="content">
                                                        <div className="container">
                                                            <h2>¿Estás seguro que quieres eliminar a este cliente?</h2>
                                                            <p>Esta acción no se puede deshacer</p>
                                                            <div className="btns-container">
                                                                <button className="btn-delete" onClick={this.deleteById}> <FontAwesomeIcon icon={['fas', 'times']}  /> Sí, eliminar</button>
                                                                <button className="btn-cancel" onClick={this.dialogDelete}>No, fue un error</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ( null )
                                    }

                                </div>

                            </div>

                        </section>
                    </div>
                </div>
            );
        } else {
            if(localStorage.getItem('user-role') === "admin"){
                return(
                    <Redirect to={"/admin"}/>
                )
            }
            if( localStorage.getItem('user-role') === "client") {
                return (
                    <Redirect to={"/client"}/>
                )
            } else {
                return (
                    <Redirect to={"/"}/>
                )
            }
        }
    }
}

export default SadminClientes;
