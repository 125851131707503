/* eslint-disable */
import React, { Component } from 'react';
import Sidebar from "./../Sidebar/Sidebar"
import {Redirect} from "react-router-dom";
import icon from "./../../Images/svg/girl.svg"

import "./AdministracionClientes.scss"
import "./../styles/ComponentStyles.sass"
import "./../styles/PopUpSytyles.scss"
import "./../styles/hover.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/*
    TODO: MODULO DE PAGOS, EN FORMA DE TABLA, ESPERAR A QUE IVÁN ME MANDE DIRECCION Y FECHA Y HORA
*/


class AdminClientes extends Component {

    constructor() {
        super()
        this.state = {
            clients : [],
            clientsSort : [],
            showAll : true,
            filterCardsByAlphabet : false,
            filterCardsByMen : false,
            filterCardsByWomen : false,
            filterCardsByName : false,
            nameFilter : ""
        }
    }

    componentWillMount() {
        this.getClients()
    }

    filterCardsByName = () => {
        if (this.refs.filter_name.value.length > 0) {
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_alphabet.checked = false;
            this.filterCardsByMen();
            this.filterCardsByWomen();
            this.filterCardsByAlphabet();
            this.setState({ showAll: false, filterCardsByName: true, nameFilter: this.refs.filter_name.value })
        }
        else { this.setState({ showAll: true, filterCardsByName: false, nameFilter: "" }) }
    }

    filterCardsByAlphabet = () => {
        if(this.refs.filter_alphabet.checked){
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.filterCardsByName()
            this.filterCardsByMen();
            this.filterCardsByWomen();
            let sorted = this.state.clients.sort(function (o1, o2) {
                if (o1.name > o2.nane) { return 1; } 
                else if (o1.name < o2.name) { return -1; }
                return 0;
            });
            this.setState({ showAll: false,  clientsSort : sorted, filterCardsByAlphabet: true })
        }else {
            this.getClients()
            this.setState({ filterCardsByAlphabet: false,  showAll: true, clientsSort : []  })
        }
    }
    filterCardsByMen = () => {
        if(this.refs.filter_men.checked){
            this.refs.filter_name.value = ""
            this.refs.filter_women.checked = false
            this.refs.filter_alphabet.checked = false
            this.filterCardsByName()
            this.filterCardsByWomen()
            this.filterCardsByAlphabet()
            this.setState({ showAll: false, filterCardsByMen: true })
        } else {
            this.setState({ filterCardsByMen: false, showAll: true })
        }
    }

    filterCardsByWomen = () => {
        if (this.refs.filter_women.checked) {
            this.refs.filter_name.value = ""
            this.refs.filter_men.checked = false
            this.refs.filter_alphabet.checked = false
            this.filterCardsByName()
            this.filterCardsByMen()
            this.filterCardsByAlphabet()
            this.setState({ showAll: false, filterCardsByWomen: true })
        } else {
            this.setState({ filterCardsByWomen: false, showAll: true })
        }
    }

    restoreFilters = () => {  
        if(this.refs.filter_alphabet.checked || this.refs.filter_men.checked || this.refs.filter_women.checked || this.refs.filter_name.value.length > 0){
            this.refs.filter_alphabet.checked = false;
            this.refs.filter_men.checked = false;
            this.refs.filter_women.checked = false;
            this.refs.filter_name.value = "";
            this.filterCardsByAlphabet();
            this.filterCardsByName();
            this.filterCardsByWomen();
            this.filterCardsByMen();
        } else {
            alert("No hay ningún filtro aplicado")
        }
    } 

    getClients = () => {
        fetch("https://api-leia.herokuapp.com/user/getAll").then(response => response.json()).then((myJson) =>  this.setState( {  clients: myJson.users.filter( c => c.role === "cliente" )  } ) )
    }

    

    addUser = () => {
        let data = {
            name: "Simon",
            firstName: "Lopez",
            secondName: "Valtierra",
            birthDate: "22/12/1998",
            email: "simon.valt23@gmail.com",
            phoneNumber: "476478492",
            address: "San Pancho Street #780",
            gender: "Hombre",
            status : "activo",
            photo: "https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/users%2Fsimon.jpg?alt=media&token=6b4c99c4-8d09-4cab-84df-e1ac85c955d7",
            code: "56728",
            username: "simon",
            password: "simonbato",
        }
        fetch("https://api-leia.herokuapp.com/user/new-user", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error));
    }



    render() {
        if(localStorage.getItem('user-role') === "admin" || localStorage.getItem('user-role') === "sadmin"){
            return (
                <div className="module admin-clientes">
                    <Sidebar type="admin" />
                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={icon} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de clientes</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">
                                <div className="searchfield">
                                    <input type="text" placeholder="buscar cliente" onChange={this.filterCardsByName} ref="filter_name"/>
                                    <div><label><span><FontAwesomeIcon icon={['fas', 'search']} /></span></label></div>
                                </div>
                                <div className="filter-row">
                                    <p className="title">filtros</p>
                                    <div className="options">
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByAlphabet} ref="filter_alphabet" />
                                            <label> A - Z </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByMen} ref="filter_men" />
                                            <label> Hombres </label>
                                        </div>
                                        <div className="option">
                                            <input type="checkbox" onChange={this.filterCardsByWomen} ref="filter_women" />
                                            <label> Mujeres </label>
                                        </div>
                                        <div className="option option-no-border">
                                            <button onClick={this.restoreFilters}>restaurar</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="admin-client-cards">
                                    {
                                        this.state.showAll ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.status === "activo"
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role}</p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByName ?
                                            (
                                                this.state.clients.filter(
                                                    client => (client.name + " " + client.firstName + " " + client.secondName).includes(this.state.nameFilter)
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role}</p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByAlphabet ?
                                            (
                                                this.state.clientsSort.map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role}</p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByMen ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.gender.includes("Hombre")
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role}</p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                    {
                                        this.state.filterCardsByWomen ?
                                            (
                                                this.state.clients.filter(
                                                    client => client.gender.includes("Mujer")
                                                ).map(
                                                    client =>
                                                        <div key={client.id} className="card">
                                                            <p className="tipo">{client.role}</p>
                                                            <div className="header-tile">
                                                                <img src={client.photo} alt="" />
                                                                <div><p className="nombre">{client.name + " " + client.firstName + " " + client.secondName}</p></div>
                                                            </div>
                                                            <div className="data-tile">
                                                                <label className="lbl-8" > <span><FontAwesomeIcon icon={['fas', 'envelope']} /></span> {client.email} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'phone']} /></span> {client.phoneNumber} </label>
                                                                <label className="lbl-8"> <span><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> {client.address} </label>
                                                                <label className="lbl-4"> <span><FontAwesomeIcon icon={['fas', 'map-pin']} /></span> {client.code} </label>
                                                            </div>
                                                            <div className="buttons-container">
                                                                <a className="btn-messenger" href={"mailto:" + client.email + "?subject=Hola!!&body=" + "Soy parte del equipo de Leia."}> <span><FontAwesomeIcon icon={['fas', 'comment-dots']} /></span> Mensaje </a>
                                                            </div>
                                                        </div>
                                                )
                                            ) : (null)
                                    }

                                </div>

                            </div>

                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to={"/client"} />
            )
        }
    }
}

export default AdminClientes;
