import React, { Component } from 'react';
import "./Dashboard.css"
import Sidebar from "../Sidebar/Sidebar"
import cloud from "./../../Images/svg/cloud.svg"


import UserCards from './UserCard/UserCards';
import {Redirect} from "react-router-dom";


class DashboardSadmin extends Component {
    render() {
        if(localStorage.getItem('user-role') === "sadmin") {
            return (
                <div className="dashboard">
                    <Sidebar type="sadmin" />
                    <section className="dashboard-main dashboard-sadmin">
                        <div className="container">
                            <h1>Bienvenido, {localStorage.getItem('user-name')} </h1>
                            <UserCards type="sadmin" />
                            <div className="dashboard-weather">
                                <div>
                                    <img src={cloud} alt="weather" />
                                    <div>
                                        <p>
                                            24°
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <Redirect to={"/sorry-not-sorry"} />
            )
        }
    }
}

export default DashboardSadmin;
