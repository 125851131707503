import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import "./Dashboard.css"
import Sidebar from "../Sidebar/Sidebar"
import cloud from "./../../Images/svg/cloud.svg"
import UserCards from './UserCard/UserCards';


class DashboardClient extends Component {
    render() {
        if(localStorage.getItem('user-role') === "cliente") {
            return (
                <div className="dashboard">
                    <Sidebar type="cliente"/>
                    <section className="dashboard-main dashboard-client">
                        <div className="container">
                            <h1>Bienvenido, {localStorage.getItem('user-name')} </h1>
                            <UserCards type="cliente" />
                            <div className="dashboard-weather">
                                <div>
                                    <img src={cloud} alt="weather" />
                                    <div>
                                        <p>
                                            24°
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            );
        } else {
            return (
                <Redirect to={"/"} />
            )
        }
    }
}

export default DashboardClient;
