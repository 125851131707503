/* eslint-disable */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Redirect} from "react-router-dom"
import "./ReferidosCliente.scss"
import "./../styles/ComponentStyles.sass"
import image from "./../../Images/svg/email.svg"
import icon from "./../../Images/svg/group.svg"
import Sidebar from '../Sidebar/Sidebar';

class ReferidosCliente extends Component {

    constructor(){
        super()
        this.state = {
            userCode: "4paXnw"
        }
    }

    sendWhatsappClienteCode = () => {
        return <Redirect to={"https://wa.me/?text=" + this.state.userCode} />
    }


    render() {

        return (
            <div className="module referidos">
                    <Sidebar type="cliente" />

                    <div className="module-container">
                    <section className="content">
                        <div className="referidos-header">
                            <img src={icon} alt="icon" className="icon"/>
                            <div className="title">
                                <h2>Invitar amigos</h2>
                            </div>
                            <hr />
                        </div>

                        <section className="referidos-content">
                            <h2>Invita amigos a Leia y gana recompensas!</h2>
                            <div className="content-image">
                                <img src={image} alt="envelope" />
                            </div>
                            <h3>Envía tu código de referidos</h3>
                            <p className="referidos-codigo">{this.state.userCode}</p>
                            <div className="referidos-envio">
                                
                                <a href={"mailto:direccion@destinatario.com?subject=Bienvenido%20a%20Leia!!&body=" + "Usa este código en Leia para obtener descuentos: " + this.state.userCode} ><button className="btn-pur"><span><FontAwesomeIcon icon={['fas', 'envelope']} /></span>Enviar por email</button></a>
                                <a target="_blank" href={"https://wa.me/?text=" + this.state.userCode} rel="noopener noreferrer" ><button className="btn-wh"><span><FontAwesomeIcon icon={['fab', 'whatsapp']} /></span>Enviar por whatsapp</button></a>
                                <a target="_blank" href={"https://m.me"} rel="noopener noreferrer" ><button className="btn-bl"><span><FontAwesomeIcon icon={['fab', 'facebook-messenger']} /></span>Enviar por messenger</button></a>
                            </div>
                        </section>
                    </section>


                    </div>

            </div>
        );
    }
}

export default ReferidosCliente;
