import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Td extends Component {
    render() {
        return(
            <td>
                <span>
                    <FontAwesomeIcon icon={this.props.icon}/>
                </span>
                {this.props.text}
            </td>
        )
    }
}

export default Td;