import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Redirect} from "react-router-dom"
import "./stylesLandingPage.css"
import Header from "./../header/Header"
import Cover from "./../cover/Cover"
import Td from "./../Td/Td"
import WebCard from "./../WebCard/Webcard"
import Photos from "./../Photos/Photos"
import Testimonial from "./../Testimonial/Testimonial"
import Contacto from "./../Contacto/Contacto"
// import Ubicacion from "./../Ubicacion/Ubicacion"
import Footer from "./../Footer/Footer"
import StatelessDialog from "../../../App/Components/StatelessDialog/StatelessDialog";

class Home extends Component {

    constructor () {
        super()
        this.state = {
            redirectClient : false,
            redirectAdmin : false,
            redirectSa : false,
            dialog : {
                status : false,
                info : {
                    title : "",
                    tipo : "",
                    closer : ""
                }
            }
        }
    }

    componentWillMount() {
        sessionStorage.clear()
        localStorage.clear()
    }


    loginSadmin = () => {
        this.setState({
            redirectSa : true
        })
    }

    loginAdmin = () => {
        this.setState({
                redirectAdmin : true
        })
    }

    loginClient = () => {
        this.setState({
            redirectClient : true
        })
    }

    handleDialog = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    closerDialog = () => {
        this.handleDialog("none", "")
    }

    render() {

        if (this.state.redirectClient) {
            return < Redirect to='/client' />
        } else if (this.state.redirectAdmin){
            return < Redirect to='/admin' />
        } else if ( this.state.redirectSa) {
            return <Redirect to="/sadmin"/>
        }

        return (
            <div> {
                this.state.dialog.status ? (
                    <StatelessDialog dialog={this.state.dialog} closer={this.closerDialog}/>
                ) : null
            }
                <Header loginClient={this.loginClient} loginAdmin={this.loginAdmin} loginSa={this.loginSa} dialogHandler={this.handleDialog} closerDialog={this.closerDialog} />
                <Cover />

                <div className="servicios" id="servicios">
                    <div className="container">
                        <h2>Servicios</h2>
                        <hr className="line" />
                        <table className="servicios-list">
                            <tbody>
                                <tr>
                                    <Td icon={['fas', 'check']} text="Páginas web" />
                                    <Td icon={['fas', 'check']} text="Weareables" />
                                    <Td icon={['fas', 'check']} text="Publicidad" />
                                    <Td icon={['fas', 'check']} text="Asesoría legal" />
                                </tr>
                                <tr>
                                    <Td icon={['fas', 'check']} text="Aplicaciones móviles" />
                                    <Td icon={['fas', 'check']} text="Software a la medida" />
                                    <Td icon={['fas', 'check']} text="Organización de eventos" />
                                    <Td icon={['fas', 'check']} text="Asesoría empresarial" />
                                </tr>
                                <tr>
                                    <Td icon={['fas', 'check']} text="Aplicaciones web" />
                                    <Td icon={['fas', 'check']} text="Marketing digital" />
                                    <Td icon={['fas', 'check']} text="Talleres" />
                                    <Td icon={['fas', 'check']} text="Consultoría tecnológica" />
                                </tr>
                                <tr>
                                    <Td icon={['fas', 'check']} text="Aplicaciones de escritorio" />
                                    <Td icon={['fas', 'check']} text="Marketing tradicional" />
                                    <Td icon={['fas', 'check']} text="Conferencias" />
                                    <Td icon={['fas', 'check']} text="Relaciones públicas" />
                                </tr>
                            </tbody>

                        </table>

                        <div className="servicios-cards">
                            <WebCard
                                imageType="icon"
                                icon={['fas', 'laptop']}
                                title="Proyectos tecnológicos"
                                description="Desarrollo de software a la medida, es decir, proyectos que implican la interacción de distintos sistemas operativos y/o software específico."
                            />

                            <WebCard
                                imageType="icon"
                                icon={['fas', 'calendar-alt']}
                                title="Conferencias y talleres"
                                description="Desarrollamos eventos de cualquier tipo, así como conferencias y talleres de emprendimiento, tecnología, capital intelectual, fiscal y legal."
                            />

                            <WebCard
                                imageType="icon"
                                icon={['fas', 'users']}
                                title="Marketing y publicidad"
                                description="Campañas de marketing y publicidad tradicional y a la medida. Branding digital y estrategias digitales acorde al mercado necesario."
                            />

                            <WebCard
                                imageType="icon"
                                icon={['fas', 'briefcase']}
                                title="Consultoría legal y tecnológica"
                                description="Se aplica para proyectos preventivos y/o correctivos relacionados con procesos que pueden mejorar con la tecnología o a nivel empresarial."
                            />
                        </div>


                    </div>
                </div>{/* Fin de servicios */}

                <div className="nosotros" id="nosotros">
                    <div className="container">

                        <h2>Nosotros</h2>
                        <hr className="line" />
                        <p className="nosotros-description">Para nosotros, lo más importante son las personas, puesto que son las que ejecutan las operaciones cruciales para que nuestros clientes siempre estén satisfechos.</p>
                        <div className="nosotros-cards">

                            <div className="nosotros-card">
                                <h2>
                                    <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                                    Actualidad y vanguardia
                        </h2>
                                <p>El equipo de Leia está siempre actualizado y con la vista fija en el futuro para ofrecerte servicios creativos.</p>
                            </div>

                            <div className="nosotros-card">
                                <h2>
                                    <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                                    Nos involucramos
                        </h2>
                                <p>En Leia siempre estamos dispuestos a escuchar las propuestas de nuestros clientes y a desarrollarlo de la mejor forma posible.</p>
                            </div>

                        </div>{/* Fin de nosotros cards*/}

                        <div className="nosotros-equipo">
                            <div className="nosotros-equipo-title">
                                <hr className="line" />
                                <h3>Equipo de trabajo</h3>
                                <hr className="line" />
                            </div>
                            <Photos />
                        </div>

                    </div>
                </div>{/* Fin de nosotros */}

                <Testimonial />

                <Contacto />

                {/* <Ubicacion /> */}

                <Footer />

            </div>
        );
    }
}

export default Home;
