import React, { Component } from 'react';
import "./../styles/ComponentStyles.sass"
import "./conf.sass"

import Sidebar from "./../Sidebar/Sidebar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Redirect} from "react-router-dom";
import firebase from "firebase";
import StatelessDialog from "../StatelessDialog/StatelessDialog";

class ConfiguracionSadmin extends Component {

    constructor(){
        super()
        this.state = {
            user : {
                id : "",
                nombre : " ",
                apellidoP : " ",
                apellidoM : " ",
                codigopostal : " ",
                direccion : " ",
                telefono : " ",
                email : " ",
                foto : " ",
                usuario : " ",
                contraseña : " ",
                extracto : " "
            },
            dialog : {
                status : false, info : {}
            }
        }
    }

    componentWillMount() {
        console.log(JSON.parse(localStorage.getItem('user')))
        this.setUser()
    }

    setUser = () => {
        let u = JSON.parse(localStorage.getItem('user'));
        let user = []
        user.id = u.id;
        user.nombre = u.name;
        user.apellidoP = u.firstName;
        user.apellidoM = u.secondName;
        user.codigopostal = u.postalCode;
        user.extracto = u.extract;
        user.direccion = u.address;
        user.telefono = u.phoneNumber;
        user.email = u.email;
        user.foto = u.photo;
        user.usuario = u.username;
        user.contraseña = u.password;
        this.setState( {
            user : user
        })
    }

    handleNombre = (e) => {
        this.setState({
            user : {
                ...this.state.user,
                nombre : e.target.value
            }
        })
    }

    handleInput = (e) => {
        switch (e.target.name) {
            case "nombre":
                this.setState({ user : { ...this.state.user, nombre : e.target.value } })
                break;
            case "apellidoP":
                this.setState({user : { ...this.state.user, apellidoP : e.target.value } });
                break;
            case "apellidoM":
                this.setState({user : { ...this.state.user, apellidoM : e.target.value } });
                break;
            case "email":
                this.setState({user : { ...this.state.user, email : e.target.value } });
                break;
            case "telefono":
                this.setState({user : { ...this.state.user, telefono : e.target.value } });
                break;
            case "codigopostal":
                this.setState({user : { ...this.state.user, codigopostal : e.target.value } });
                break;
            case "direccion":
                this.setState({user : { ...this.state.user, direccion : e.target.value } });
                break;
            case "usuario":
                this.setState({user : { ...this.state.user, usuario : e.target.value } });
                break;
            case "contraseña":
                this.setState({user : { ...this.state.user, contraseña : e.target.value } });
                break;
            case "extracto":
                this.setState({user : { ...this.state.user, extracto : e.target.value } });
                break;

        }
    }

    updateUser = () => {
        if(this.vacios()){
            this.dialogHandler("error", "Por favor llena todos los campos")
        }
        else {
            this.dialogHandler("loading", "Actualizando datos...")
            let user = {
                id : this.state.user.id,
                address : this.state.user.direccion,
                email : this.state.user.email,
                name : this.state.user.nombre,
                firstName : this.state.user.apellidoP,
                secondName : this.state.user.apellidoM,
                phoneNumber : this.state.user.telefono,
                postalCode : this.state.user.codigopostal,
                username : this.state.user.usuario,
                password : this.state.user.contraseña,
                photo : this.state.user.foto,
                extract : this.state.user.extracto
            }
            fetch("http://localhost:5000/user/updateUser", {
                method: 'POST',
                body: JSON.stringify(user),
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then(response => {
                    console.log(response)
                    localStorage.setItem('user',JSON.stringify(user))
                    this.setUser()
                    this.dialogHandler("ready", "Datos actualizados!")
                    setTimeout(this.dialogHandler("none", ""), 600)
                })
                .catch(error => {
                    console.error('Error:', error)
                    this.dialogHandler("error", "Ocurrió un error")
                });
        }
    }

    clickRef = () => {
        this.inputImage.click();
    }

    updateImage = (e) => {
        if(e.target.files.length > 0){
            let file = e.target.files[0]
            const storageref = firebase.storage().ref(`/products/${file.name}`);
            const task = storageref.put(file)
            this.dialogHandler("loading", "Actualizando imagen")
            task.on(firebase.storage.TaskEvent.STATE_CHANGED,
                {
                    'complete': () => {
                        storageref.getDownloadURL().then(url => {
                            this.setState({
                                user : {
                                    ...this.state.user,
                                    foto : url
                                }
                            })
                            this.dialogHandler("ready", "Imagen de perfil actualizada!")
                            setTimeout(() => {
                                this.dialogHandler("none", "")
                            }, 700)
                        })

                    }
                })
        }
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }

    vacios = () => {
        if(this.state.user.nombre.length > 0 && this.state.user.apellidoP.length > 0 && this.state.user.apellidoM.length > 0 && this.state.user.telefono.length > 0 && this.state.user.email.length > 0 && this.state.user.foto.length > 0 && this.state.user.direccion.length > 0 && this.state.user.usuario.length > 0 && this.state.user.contraseña.length > 0 && this.state.user.extracto.length > 0) {
            return false
        } else {
            return true
        }
    }

    closeDialog = () => this.setState({dialog : {status : false, info : {}}})


    render() {

        if(localStorage.getItem('user-role') === "sadmin") {
            return (
                <div className="module configuracion">
                    <Sidebar type="sadmin" />
                    <div className="module-container">

                        <section className="content">

                            <h2>Configuración de la cuenta</h2>
                            <hr className="title-line-bottom"/>

                            <div className="datos datos-personales">

                                <div className="title">
                                    <h3>Datos personales</h3>
                                </div>

                                <div className="forms">
                                    <form>

                                        <div className="form-element-image">
                                            <img src={this.state.user.foto} alt="" onClick={this.clickRef} />
                                            <input type="file" style={{display:'none'}} accept="image/png, image/jpeg" ref={input => this.inputImage = input} onChange={this.updateImage}/>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="text" placeholder={"Nombre"} required name={"nombre"} onChange={this.handleNombre} value={this.state.user.nombre}/>
                                            <label>Nombre</label>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="text" placeholder={"Apellido Paterno"} required name={"apellidoP"} onChange={this.handleInput} value={this.state.user.apellidoP} />
                                            <label>Apellido Paterno</label>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="text" placeholder={"Apellido Materno"} required name={"apellidoM"} onChange={this.handleInput} value={this.state.user.apellidoM}/>
                                            <label>Apellido Materno</label>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="text" placeholder={"Email"} required name={"email"} onChange={this.handleInput} value={this.state.user.email}/>
                                            <label>Email</label>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="number" placeholder={"Teléfono"} required name={"telefono"} onChange={this.handleInput} value={this.state.user.telefono} />
                                            <label>Teléfono</label>
                                        </div>

                                        <div className="form-element elm-m">
                                            <input type="number" placeholder={"Código postal"} required name={"codigopostal"} onChange={this.handleInput} value={this.state.user.codigopostal} />
                                            <label>Código Postal</label>
                                        </div>

                                        <div className="form-element elm-lg">
                                            <input type="text" placeholder={"Dirección"} required name={"direccion"} onChange={this.handleInput} value={this.state.user.direccion} />
                                            <label>Dirección</label>
                                        </div>

                                        <div className="form-element elm-mm">
                                            <input type="text" placeholder={"Usuario"} required name={"usuario"} onChange={this.handleInput} value={this.state.user.usuario} />
                                            <label>Usuario</label>
                                        </div>

                                        <div className="form-element elm-mm">
                                            <input type="text" placeholder={"Contraseña"} required name={"contraseña"} onChange={this.handleInput} value={this.state.user.contraseña} />
                                            <label>Contraseña</label>
                                        </div>


                                        <div className="form-element elm-lg">
                                            <textarea placeholder="Ej. Lorem ipsum dolor sit amet" onChange={this.handleInput} value={this.state.user.extracto} name={"extracto"}/>
                                            <label>Extracto</label>
                                        </div>

                                    </form>
                                </div>

                            </div>

                            {/* <div className="datos datos-pago">
                                <div className="title">
                                    <h3>Tarjetas de pago</h3>
                                </div>

                                <div className="tarjetas">
                                    <div className="nueva-tarjeta">
                                        <p>Introduce la información de la nueva tarjeta</p>
                                        <div className="nueva-tarjeta-inputs">
                                            <form>
                                                <div className="form-element elm-s">
                                                    <input type="text" placeholder="Ej. Carol Danvers"  required/>
                                                    <label>Nombre en tarjeta</label>
                                                </div>
                                                <div className="form-element elm-s">
                                                    <input type="number" placeholder="Ej. 235632"  required/>
                                                    <label>Número de tarjeta</label>
                                                </div>
                                                <div className="form-element elm-s">
                                                    <div className="elm-double">
                                                        <input type="number" placeholder="Ej. 01" required />
                                                        <input type="number" placeholder="Ej. 2019"  required/>
                                                    </div>
                                                    <label>Fecha de expiración</label>
                                                </div>
                                                <div className="form-element elm-s ">
                                                    <div className="form-element-button">
                                                        <button>Añadir tarjeta</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}

                            <div className="action-buttons">
                                <button onClick={this.updateUser}>Guardar <span><FontAwesomeIcon icon={['fas', 'save']} /></span></button>
                            </div>

                        </section>
                    </div>

                    {
                        this.state.dialog.status ? <StatelessDialog dialog={this.state.dialog} closer={this.closeDialog}/> : null
                    }

                </div>
            );
        } else {
            return (
                <Redirect to={"/sorry-not-sorry"} />
            )
        }
    }
}

export default ConfiguracionSadmin;
