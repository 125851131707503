/* eslint-disable */
import React, { Component } from 'react';
import "./SignInForm.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SignInForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            redirect : false,
            user : "",
            password : "",
            email : "",
            modeSign: "true",
            registrarusername : "",
            registraremail : "",
            registrarpassword : "",
            registrarsecpassword : "",
            error : "",
            isInvalid : true,
            isInvalidLogin : true,
            isMounted : false,
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        })
    }

    componentWillUnmount() {
        this.setState({
            isMounted : false
        })
    }


    isInvalid = () => {
        if(this.state.registrarsecpassword.length > 0  && this.state.registraremail.length > 0 && this.state.registrarusername.length > 0 && this.state.registrarpassword.length > 0 && this.state.registrarsecpassword === this.state.registrarsecpassword )
        {
            this.setState({ isInvalid : false })
        }
        else {
            this.setState({ isInvalid : true })
        }
    }

    isInvalidLogin = () => {
        if( this.state.password.length > 0 && this.state.user.length > 0 ) {
            this.setState({ isInvalidLogin : false })
        } else {
            this.setState({ isInvalidLogin : true })
        }
    }

    handleRedirect = response => {
        if(response.status){
            localStorage.setItem('userId', response.account.key);
            localStorage.setItem('user-name', response.account.name);
            localStorage.setItem('user-role', response.account.role);
            localStorage.setItem('user', JSON.stringify(response.account));
            localStorage.setItem('carrito', "");
            //console.log(response)
            if (response.account.role === "cliente") {
                this.setState(
                    {
                        redirect : true
                    }
                )
                this.props.loginClient()
                //this.forceUpdate()
            }
            if (response.account.role === "admin") {
                this.setState(
                    {
                        redirect : true
                    }
                )
                this.props.loginAdmin()
                //this.forceUpdate()
            }
            if (response.account.role === "sadmin") {
                this.setState(
                    {
                        redirect : true
                    }
                )
                this.props.loginSadmin()
                //this.forceUpdate()
            }
        }
        else {
            console.log(response)
        }
        
    } 

    toggleMode = () => {
        this.setState(
            {modeSign : !this.state.modeSign}
        )
    }

    handleName = (e) => {
        this.setState(
            {
                user : e.target.value
            }
        )
    }

    handleEmail = (e) => {
        this.setState({
            email : e.target.value
        })
    }

    handlePassword = (e) => {
        this.setState(
            {
                password : e.target.value
            }
        )
        this.isInvalidLogin()
    }

    handleNewUsername = e => this.setState({ registrarusername : e.target.value })
    handleNewEmail = e => this.setState({ registraremail : e.target.value })
    handleNewPassword = e => this.setState({ registrarpassword : e.target.value })
    handleNewPasswordConfirm = e => { this.setState({ registrarsecpassword : e.target.value }); this.isInvalid()  }

    login = (e) => {
        e.preventDefault();
        if( this.state.isMounted === true ){
            this.props.close()
            localStorage.clear()
            this.props.dialogHandler("loading", "Iniciando Sesión")
            let data = { any: this.state.user, password: this.state.password }
            fetch("https://api-leia.herokuapp.com/user/signin", {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(response => {
                    this.props.dialogHandler("ready", "Bienvenido!")
                    this.handleRedirect(response);
                })
                .catch(error => {
                    console.error('Error:', error)
                    this.props.dialogHandler("error", "Fallo en el inicio de sesión!")
                });
        }
    }

    registrarUsuario = (e) => {
        e.preventDefault()
        this.props.dialogHandler("loading", "Registrando...")

        let data = {
            name: this.state.registrarusername,
            username : this.state.registrarusername,
            status : "activo",
            email : this.state.registraremail,
            password: this.state.registrarpassword,
            image : "https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/Servicios%2Fdescarga.png?alt=media&token=09cff4d0-6ec5-45ba-b2a0-16df11137653"
        };
        console.log(data);
        fetch("https://api-leia.herokuapp.com/user/signUp", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response)
                this.props.dialogHandler("ready", "Registrado!")
            }) // if the query is accepted
            .catch(error => {
                console.error('Error:', error)
                this.props.dialogHandler("error", "Ocurrió un error!")
            }); // if not, throws error
    }

    /*
        TODO: Replace calling "loginS" for "login" in onClick event for button sign in
    */
    loginS = () => {
        if(this.state.user === "Ruben") {
            this.props.loginClient()
        } else if (this.state.user === "Carlos"){
            this.props.loginAdmin()
        } else if(this.state.user === "Ana"){
            this.props.loginSa()
        }
    }

    render() {

    {/*
            if (this.state.redirect) {
                return < Redirect to='/client' />
            }
        */
    } 

        return (
            <div className="sign-in-form">

                    {this.state.modeSign ? (
                        <div className="containerSi">
                            <h3>Iniciar Sesion</h3>

                            <form onSubmit={this.login}>
                                <div className="form-row">
                                    <input type="text" placeholder="Usuario o correo" value={this.state.username} required onChange={this.handleName}/>
                                    <span> <FontAwesomeIcon icon={['fas', 'user']} /> </span>
                                </div>
                                <div className="form-row">

                                    <input type="password" placeholder="Password"  value={this.state.password} required ref={element => this._user = element} onChange={this.handlePassword}/>
                                    <span> <FontAwesomeIcon icon={['fas', 'key']} /> </span>
                                </div>
                                <div className="form-row">
                                        <button id="sign-ing" type={"submit"} disabled={this.state.isInvalidLogin}>
                                            Entrar
                                <span><FontAwesomeIcon icon={['fas', 'arrow-right']} /></span>
                                        </button>
                                </div>
                            </form>

                            {/*
                            <div className="sign-in-alt">
                                <button className="sign-with bg-red" onClick={this.redi}>
                                    <span className="icon"><FontAwesomeIcon icon={['fab', 'google']} /></span>
                                    <span className="vertical-line" />
                                    <span className="text">Entrar con Google</span>
                                </button>
                                <button className="sign-with bg-blue">
                                    <span className="icon"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span>
                                    <span className="vertical-line" />
                                    <span className="text">Entrar con Facebook</span>
                                </button>
                            </div>

                            */}

                            <div>
                                <p>¿No tienes cuenta? <span><a href="#registrate" onClick={this.toggleMode}>Regístrate</a></span> </p>

                            </div>
                        </div>
                    ):

                    <div className="containerSi">
                        <h3>Registrarse</h3>

                        <form>

                            <div className="form-row">
                                <input type="text" value={this.state.registrarusername} onChange={this.handleNewUsername} placeholder="Usuario" required />
                                <span> <FontAwesomeIcon icon={['fas', 'check']} /> </span>
                            </div>

                            <div className="form-row">
                                <input type="email" value={this.state.registraremail} onChange={this.handleNewEmail}  placeholder="Email" required  ref={element => this._email = element} />
                                <span> <FontAwesomeIcon icon={['fas', 'envelope']} /> </span>
                            </div>

                            <div className="form-row">
                                <input type="password" value={this.state.registrarpassword} onChange={this.handleNewPassword}  placeholder="Password" required ref={element => this._password1 = element}/>
                                <span> <FontAwesomeIcon icon={['fas', 'key']} /> </span>
                            </div>

                            <div className="form-row">
                                <input type="password" value={this.state.registrarsecpassword} onChange={this.handleNewPasswordConfirm}  placeholder="Confirmar password" required ref={element => this._password2 = element}/>
                                <span> <FontAwesomeIcon icon={['fas', 'key']} /> </span>
                            </div>

                            <div className="form-row">
                                <a className="a-button">
                                    <button type={"submit"} disabled={this.state.isInvalid} id="sign-ing" onClick={this.registrarUsuario}>
                                        Registrarse
                                        <span><FontAwesomeIcon icon={['fas', 'arrow-right']} /></span>
                                    </button>
                                </a>
                            </div>
                        </form>

                        {/*
                         <div className="sign-in-alt">
                            <button className="sign-with bg-red">
                                <span className="icon"><FontAwesomeIcon icon={['fab', 'google']} /></span>
                                <span className="vertical-line" />
                                <span className="text">Registrarse con Google</span>
                            </button>
                            <button className="sign-with bg-blue">
                                <span className="icon"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span>
                                <span className="vertical-line" />
                                <span className="text">Registrarse con Facebook</span>
                            </button>
                        </div>

                        */}

                        <div>
                            {this.state.error.length > 0 ? <p>this.state.error.message</p> : null }
                            <p>¿Ya tienes cuenta? <span><a href="#registrate" onClick={this.toggleMode}>¡Inicia Sesión!</a></span> </p>

                        </div>
                    </div>

        }

            </div>
        );
    }
}

export default SignInForm;
