import React, { Component } from 'react';
import "./Dashboard.css"
import {Redirect} from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar"
import cloud from "./../../Images/svg/cloud.svg"
import UserCards from './UserCard/UserCards';

class DashboardAdmin extends Component {
    render() {
        if(localStorage.getItem('user-role') === "admin"){
            return (
                <div className="dashboard">
                    <Sidebar type="admin" />
                    <section className="dashboard-main dashboard-admin">
                        <div className="container">
                            <h1>Bienvenido, Steve </h1>
                            <UserCards type="admin" />
                            <div className="dashboard-weather">
                                <div>
                                    <img src={cloud} alt="weather" />
                                    <div>
                                        <p>
                                            24°
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
        else {
            return(
                <Redirect to={"/client"} />
            )
        }
    }
}

export default DashboardAdmin;
