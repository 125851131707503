import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import "./Payment.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class PaymentSuccessClient extends Component {

    constructor(){
        super()
        this.state = {
            aproved : false
        }
    }

    componentWillMount() {
        this.getUrlParams()
    }

    getUrlParams = () => {
        const query = new URLSearchParams(this.props.location.search);
        let data = {
            paymentId: query.get('paymentId'),
            PayerID: query.get('PayerID'),
            //total : query.get('total')
            total : localStorage.getItem('totalPayment')
        };
        console.log(data)
        fetch("https://api-leia.herokuapp.com/payment/paypal/succes", {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log(response)
                if(response.payment.state === 'approved'){
                    this.setState({
                        aproved : true
                    })
                    localStorage.setItem('carrito', "")
                }
            })
            .catch(error => console.error('Error:', error));
    };



    render() {
        return (
            <div className={"module payment payment-success-client"}>
                <Sidebar type={"cliente"} />

                <div className="module-container-payment">
                    <section className="content-payment">

                        <div className="header">
                            <h2>  <FontAwesomeIcon icon={['fas', 'credit-card']} />  Payment </h2>
                        </div>

                        <div className="main-payment">
                            <div className="container-payment">

                                {
                                    this.state.aproved ?
                                        (
                                            <div className={"approved"}>
                                                <p>Pago efectuado</p>
                                                <FontAwesomeIcon icon={['fas', 'check-circle']} className={"svg"}/>
                                                <Link to={"/client"} className='hvr-icon-buzz'>  <FontAwesomeIcon icon={['fas', 'arrow-left']} className={"hvr-icon"}  />  Ir a marketplace </Link>
                                            </div>
                                        ) :
                                        (
                                            <div className={"approving"}>
                                                <p>Efectuando pago...</p>
                                                <FontAwesomeIcon icon={['fas', 'spinner']} spin className={"svg"}/>
                                            </div>
                                        )
                                }

                            </div>
                        </div>

                    </section>
                </div>
            </div>
        );
    }
}

export default PaymentSuccessClient;