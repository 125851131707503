import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Servicio extends Component {

    constructor(){
        super()
        this.state = {
            seeMore : false
        }
    }

    toggleSeeMore = (e) => {
        this.setState({
            seeMore : !this.state.seeMore
        })
    }

    render() {
        return (
            <div className="servicio">
                <img src={this.props.servicio.image} alt={" "} />
                <div className="information">

                    {
                        this.state.seeMore ?
                            (
                                <div>
                                    <div className="look">
                                        <span className={"see-up"} onClick={this.toggleSeeMore}> <FontAwesomeIcon icon={['fas', 'arrow-circle-down']}  /> </span>
                                    </div>
                                    <div className="inf">
                                        <div className="row">
                                            <h3>{this.props.servicio.name}</h3>
                                        </div>
                                        <div className="row">
                                            <label>Subcategoría</label>
                                            <p>{this.props.servicio.subCategory}</p>
                                        </div>
                                        <div className="row">
                                            <label>Precio</label>
                                            <p>$ {this.props.servicio.costRange} </p>
                                        </div>
                                        <div className="row">
                                            <button id={this.props.servicio.id} onClick={() => this.props.handlerDisplay(this.props.servicio) }>
                                                Ver más
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div>
                                    <div className="look">
                                        <span className={"see-up"} onClick={this.toggleSeeMore}> <FontAwesomeIcon icon={['fas', 'arrow-circle-up']}  /> </span>
                                    </div>
                                    <div className="inf">
                                        <h3>{this.props.servicio.name}</h3>
                                        <p> $ {this.props.servicio.costRange} </p>
                                    </div>
                                </div>
                            )
                    }


                </div>
            </div>
        );
    }
}

export default Servicio

