import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "./../../img/logo.png"
import SignInForm from "./../SignInForm/SignInForm"
import "./../../../libraries/hover.css"
import "./Header.css"


class Header extends Component {

    constructor(){
        super()
        this.state = {
            isHidden : true
        }
    }

    toggleHidden = () => {
        this.setState({
            isHidden : !this.state.isHidden
        })
    }

    openSignIn = () => {
        
    }

    log = () => {
        this.props.log()
    }

    render() {
        return (
            <header>

                <div className="container">

                    <div className="header-image">
                        <img src={logo} alt="" />
                    </div>

                    <nav> 
                        <ul>
                            <a href="#home" className="hvr-underline-from-center"><li>Home</li></a>
                            <a href="#servicios" className="hvr-underline-from-center"><li>Servicios</li></a>
                            <a href="#nosotros" className="hvr-underline-from-center"><li>Nosotros</li></a>
                            <a href="#testimonios" className="hvr-underline-from-center"><li>Testimonios</li></a>
                            <a href="#contacto" className="hvr-underline-from-center"><li>Contacto</li></a>
                            {/* <a href="#ubicaciones" className="hvr-underline-from-center"><li>Ubicaciones</li></a> */}
                            <a href="#home" className="header-sign-in hvr-icon-forward" onClick={this.toggleHidden}><li className="header-sign-in-text">Iniciar Sesión</li><span className="hvr-icon"><FontAwesomeIcon icon={['fas', 'sign-in-alt']} /></span></a>
                        </ul>
                    </nav>

                </div>


                {
                    !this.state.isHidden ? (
                        <SignInForm loginClient={this.props.loginClient} loginAdmin={this.props.loginAdmin} loginSa={this.props.loginSa} dialogHandler={this.props.dialogHandler} close={this.toggleHidden} />
                    ) : (
                        null
                    )
                }


            </header>
        )
    }
}

export default Header;
