import React, {Component} from 'react';
import Sidebar from "./../Sidebar/Sidebar"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Cotizador.scss"
import MiniCard from "./MiniCard/MiniCard";
import CategoryList from "./../Marketplace/CategoryList/CategoryList";
import Servicio from "./Servicio/Servicio";
import ServiceDetails from "./ServiceDetails/ServiceDetails";
import {Redirect} from "react-router-dom";
import StatelessDialog from "./../StatelessDialog/StatelessDialog"

class Marketplace extends Component {

    constructor(){
        super()
        this.state = {
            categorias : [],
            categorySelected : [],
            servicios : [],
            subcategorias : [],
            showServiceDetail : { estatus : false, producto : [] },
            dialog  : {status : true, info : { tipo : "loading", title: "Cargando ... " }},
            serviciosCategory : []
        }
    }

    componentWillMount() {
        this.getCategorias()
    }

    getCategorias = () => {
        fetch("https://api-leia.herokuapp.com/category/getAll")
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        categorias: myJson.categories.slice(4, 8),
                        categorySelected : myJson.categories[4]
                    }
                )
                this.getServicios()
                this.filter()
                this.setState( {
                    dialog : { status : false, info : {} }
                })
            })
    }

    getServicios = () => {
        fetch("https://api-leia.herokuapp.com/quoting/get", {
            mode: 'cors',
        })
            .then(response => response.json())
            .then((myJson) => {
                //console.log(myJson.info)
                this.setState(
                    {
                        servicios: myJson.info, //.filter(p => p.status !== "inactiva")
                        serviciosCategory : myJson.info.filter( s => s.principalCategory === this.state.categorySelected.name)
                    }
                )
                //console.log( myJson.info.filter( s => s.principalCategory === this.state.categorySelected.name ) )

            })
    }

    filter = () => {
        fetch("https://api-leia.herokuapp.com/quoting/get", {
            mode: 'cors',
        })
            .then(response => response.json())
            .then((myJson) => {
                myJson = myJson
                this.setState(
                    {
                        servicios: myJson.info.filter( s => s.principalCategory === this.state.categorySelected.name ), //.filter(p => p.status !== "inactiva")
                        serviciosCategory : myJson.info.filter( s => s.principalCategory === this.state.categorySelected.name )
                    }
                )
            })
    }

    filterSubCategory = () => {
        if(this.state.subcategorias.length > 0){
            let pro = this.state.serviciosCategory
            let servicios = []
            pro.forEach(
                s => {
                    if( this.state.subcategorias.includes( s.subCategory ) ) {
                        servicios.push(s)
                    }
                }
            )
            this.setState({
                servicios : servicios
            })
        } else {
            this.filter()
        }
    }

    handleCategory = (e) => {
        let cat = this.state.categorias
        cat.forEach(c => c.selected = false)
        cat[e].selected = true
        this.setState({
            categorias : cat,
            categorySelected : cat[e],
        })
        //console.log(this.state.categorySelected.name)
        this.setState({
            subcategorias : []
        })
        this.filter()
    }

    handleSelectSubCategory = (subcategoria, method) => {
        if(method === "push"){
            let sub = this.state.subcategorias
            sub.push(subcategoria)
            this.setState({
                subcategorias : sub
            })
        } else{
            let sub = this.state.subcategorias
            sub.splice(sub.indexOf(subcategoria), 1)
            this.setState({ subcategorias : sub })
        }
        //console.log(this.state.subcategorias)
        this.filterSubCategory()
    }

    closeServiceDetail = () => {
        this.setState( {
            showServiceDetail : {
                estatus : false, producto : []
            }
        })
    }

    handleSeeMore = (servicio) => {

        console.log(servicio)
        this.setState({
            showServiceDetail : {
                estatus : false,
                servicio : servicio
            }
        })
        this.setState({
            showServiceDetail : {
                estatus : true,
                servicio : servicio
            }
        })
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    }


    render() {

        if(localStorage.getItem('userId') === null || localStorage.getItem('userId') === 'null' || localStorage.getItem('userId') === "") {
            return (
                <Redirect to={"/sorry-not-logged"}/>
            )
        } else {
            return (
                <div className={"module marketplace"}>
                    <Sidebar type={"cliente"} />

                    <div className="module-container-marketplace">

                        <section className="content-marketplace">

                            <div className="header"> <h2>  <FontAwesomeIcon icon={['fas', 'calculator']} />  Cotizador </h2> </div>



                            <div className="main-marketplace">

                                {
                                    this.state.categorias.length > 0 ?
                                        (
                                            <div className="container-marketplace">

                                                <div className="categorias-row">
                                                    <div className="title">
                                                        <h2>Categorías </h2>
                                                        <p>Selecciona la categoría que quieres explorar</p>
                                                    </div>
                                                    {
                                                        <div className="categorias-cards">
                                                            {
                                                                this.state.categorias.map(
                                                                    (c, index) =>  <MiniCard key={index} svg={c.svg} nombre={c.name} selected={c.selected} index={index} event={this.handleCategory} />
                                                                )
                                                            }
                                                        </div>
                                                    }

                                                </div>

                                                <div className="marketplace-servicios">
                                                    <div className="options">

                                                        <div className="option">
                                                            <div className="title">
                                                                <h2>{this.state.categorySelected.name}</h2>
                                                            </div>
                                                            <div className="list">
                                                                <ul className="sub-categories-list">
                                                                    {
                                                                        this.state.categorySelected.subcategories.map(
                                                                            sub =>
                                                                                <CategoryList category={sub} event={this.handleSelectSubCategory} key={sub.nombre} />
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="servicios">

                                                        {
                                                            this.state.servicios.map (
                                                                servicio => <Servicio key={servicio.id} servicio={servicio}  handlerDisplay={this.handleSeeMore} dialogHandler={this.dialogHandler} />
                                                            )
                                                        }


                                                    </div>
                                                </div>



                                            </div>
                                        ) : <p>Por favor recarga la página</p>
                                }

                            </div>

                        </section>
                    </div>

                    {
                        this.state.showServiceDetail.estatus ?
                            (
                                <ServiceDetails closer={this.closeServiceDetail}  servicio={this.state.showServiceDetail.servicio} />
                            ) : null
                    }

                    {
                        this.state.dialog.status ? <StatelessDialog  dialog={this.state.dialog} /> : null
                    }


                </div>
            );
        }

    }
}

export default Marketplace;