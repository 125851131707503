import React, { Component } from 'react';
import WebCard from "./../WebCard/Webcard"
import Nuubax from "./../../img/nuubax.png"
import Hatch from "./../../img/hatch.png"
import Qoob from "./../../img/qoob.png"



import "./Testimonial.css"

class Testimonial extends Component {
    render() {

        const e = [
            {
                img: Hatch,
                nombre: "Hatch",
                text: "“El equipo de Leia, nos ha brindado consultoría tecnológica y desarrollos a la altura de una gran startup, estamos muy contentos de colaborar con ellos”.",
                key: 1
            },
            {
                img: Qoob,
                nombre: "Qoob",
                text: "“Estamos muy agradecidos con Leia, ya que desarrolló toda nuestra tecnología. Próximamente nos estarán ayudando con la campaña de Marketing”.",
                key: 2
            },
            {
                img: Nuubax,
                nombre: "Nuubax",
                text: "“Quedamos muy conformes con el prototipado de software que nos dieron para desarrollar la primer plataforma de streaming de videojuegos en México”.",
                key: 3
            }
        ]
        return (
            <div className="testimonial" id="testimonios">
                <div className="container">
                    <h2>Testimonial</h2>
                    <hr className="line" />

                    <div className="testimonial-cards">
                        {
                            e.map( 
                                e => 
                                <WebCard imageType="image"
                                image={e.img}
                                title = {e.nombre}
                                description = {e.text}
                                key={e.key}
                                />
                                )
                        }
                    </div>

                </div> 
            </div>  
        )
    }
}

export default Testimonial