import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';

//TODO: Place inital and end dates on day picker inputs,
// FIXME: PLaceholder of discount in update ofert

import DayPickerInput from 'react-day-picker/DayPickerInput';


import "./Ofertas.scss"
import "./../styles/ComponentStyles.sass"
import "./../styles/PopUpSytyles.scss"
import "./../styles/hover.css"
import 'react-day-picker/lib/style.css';

import ofert from "./../../Images/svg/banking.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Redirect} from "react-router-dom";

class OfertasAdmin extends Component {

    constructor () {
        super()
        this.state = {
            visibleActualizar : false,
            visibleAgregar : false,
            visibleVerTodas : true,
            from : "",
            to : "",
            popUp : false,
            popUpOffer : false,
            discount : "",
            offerPrice: "",
            precioDescuentoOffer: "",
            product : { id: "", name : "nombre producto", brand : "marca producto", normalPrice : "" },
            promociones : [],
            productos : [ ],
            promocion : {id : "", name: "nombre producto", brand: "marca producto", normalPrice : "", discount : "", offerPrice : "", from:"", to:""},
        }

    }

    componentWillMount(){
        this.setPromociones()
        this.getProducts()
    }


    visActualizar = (e) => {
        this.setState (
            {
                visibleActualizar: true,
                visibleAgregar: false,
                visibleVerTodas: false,
                to : "",
                from : ""
            }
        )
    }
    visAgregar = () => {
        this.setState(
            {
                visibleAgregar: true,
                visibleActualizar: false,
                visibleVerTodas: false,
                to: "",
                from: ""
            }
        )
    }
    visVerTodas = () => {
        this.setPromociones()
        this.getProducts()
        this.setState(
            {
                visibleActualizar: false,
                visibleAgregar: false,
                visibleVerTodas: true,
                to: "",
                from: ""
            }
        )
    }

    handleIndex = e => {
        //let index = (e.target.parentNode.parentNode.rowIndex - 1) 
        //let promos = this.state.promociones.splice(index, 1)
        let idProduct = this.state.promociones[e.target.parentNode.parentNode.rowIndex - 1].id
        let data = {
            id: idProduct,
            status : "inactiva"
        }
        fetch("https://api-leia.herokuapp.com/offer/update", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.setPromociones()
            })
            .catch(error => console.error('Error:', error));
    }

    handleIndexReactivar = (e) => {
        let idProduct = this.state.promociones[e.target.parentNode.parentNode.rowIndex - 1].id
        let data = {
            id: idProduct,
            status: "activa"
        }
        fetch("https://api-leia.herokuapp.com/offer/update", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log('Success:', response);
                this.setPromociones()
            })
            .catch(error => console.error('Error:', error));
    }

    setPromociones = () => {
        fetch("https://api-leia.herokuapp.com/offer/getAll")
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        promociones : myJson.offers
                    }
                )
                this.forceUpdate()
            })
            
    }

    getProducts = () => {
        fetch("https://api-leia.herokuapp.com/product/getAll")
            .then(response => response.json())
            .then((myJson) => {
                this.setState(
                    {
                        productos: myJson.products.filter(p => p.status !== "inactiva")
                    }
                )
                this.forceUpdate()
            })
    }

    sendOffer = () => {
        let data = {
            brand : this.state.product.brand,
            name : this.state.product.name,
            normalPrice : this.state.product.normalPrice,
            discount :  this.state.discount,
            offerPrice : this.state.offerPrice.toString(),
            beginDate : this.state.from,
            endDate : this.state.to
        }
        fetch("https://api-leia.herokuapp.com/offer/add", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error));
    }

    addUser = () => {
        let data = {
            name : "Ruben",
            firstName: "Redondo",
            secondName : "Barroso",
            birthDate: "04/06/1999",
            email: "ruben@g.com",
            phoneNumber : "4773965499",
            address : "Vivienda Cooperativa #207",
            gender : "Hombre",
            photo: "https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/products%2Fruben_redondo.jpg?alt=media&token=a921f21f-ad4c-4de8-9918-89948dadb93c",
            code : "37219",
            username : "rubenx",
            password : "ruben23",
        }
        fetch("https://api-leia.herokuapp.com/user/new-user", {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error));
    }

    verificarCampos = (tipo) => {
        if(tipo === "actualizar"){
            if (this.state.from === "" || this.state.to === "" || this.state.promocion.id === "") {
                return false
            }
            else {
                return true
            }
        }else {
            if (this.state.from === "" || this.state.to === "" || this.state.product.id === "") {
                return false
            }
            else {
                return true
            }
        }
    }

    // METHOS FOR ADDING SECTION

    handleEndDate = day => {
        day = new Date(day)
        this.setState(
            {
                to : day.getDate() + "-" + (day.getMonth() + 1) + "-" + day.getFullYear()
            }
        )
    }
    handleStartDate = day => {
        day = new Date(day)
        this.setState(
            {
                from: day.getDate() + "-" + (day.getMonth() + 1) + "-" + day.getFullYear()
            }
        )
    }
    guardarPromocion = () => {
        if(this.verificarCampos()){
            alert("guarddo!")
            this.sendOffer()
        }
        else {
            alert("faltan campos")
        }
    }
    cancelarGuardar = () => {
        this.setState(
            {
                product: {
                id : "",
                nombre : "precio del producto",
                marca : "marca",
                precioNormal : ""
            },
            discount : "",
            precioDescuento : ""
        }
        )
    }
    listProducts = () => this.setState({popUp : !this.state.popUp})

    listPromos = () => this.setState({ popUpOffer: !this.state.popUpOffer })
    
    selectIndexProduct = e => {
        let index = (e.target.parentNode.parentNode.rowIndex - 1);
        this.setState(
            {
                product : {
                    id : this.state.productos[index].id,
                    name: this.state.productos[index].name,
                    brand: this.state.productos[index].brand,
                    normalPrice: this.state.productos[index].price,
                }
            }
        )
        this.listProducts();
    }
    selectIndexPromo = e => {
        let index = (e.target.parentNode.parentNode.rowIndex - 1);
        console.log(this.state.promociones[index].id)
        this.setState(
            {
                promocion: {
                    id: this.state.promociones[index].id,
                    name: this.state.promociones[index].name,
                    brand: this.state.promociones[index].brand,
                    normalPrice: this.state.promociones[index].normalPrice,
                    discount : this.state.promociones[index].discount,
                    offerPrice: this.state.promociones[index].offerPrice,
                    from: this.state.promociones[index].beginDate,
                    to: this.state.promociones[index].endDate
                }
            }
        )
        
        this.listPromos();
    }
    calculatePercentage = e => {
        if(this.state.product.normalPrice.length > 0){
            this.setState(
                {
                    discount : e.target.value
                    
                }
            )
            this.setState({
                offerPrice: Math.round((this.state.product.normalPrice - (this.state.product.normalPrice * (e.target.value / 100))))
            })
        }
        else {
            alert("no hay un producto seleccionado")
        }
    }
    calculatePercentageOffer = e => {
        if (this.state.promocion.normalPrice.length > 0) {
            this.setState(
                {
                    promocion : {
                        ...this.state.promocion, 
                        descuento : e.target.value,
                        precioOferta: Math.round((this.state.promocion.normalPrice - (this.state.promocion.normalPrice * (e.target.value / 100))))
                    }
                }
            );   
        }
        else {
            alert("no hay un producto seleccionado")
        }
    }
    actualizarPromocion = () => {
        if (this.verificarCampos("actualizar")) {
            let data = {
                id: this.state.promocion.id,
                brand: this.state.promocion.brand,
                name: this.state.promocion.name,
                normalPrice: this.state.promocion.normalPrice,
                discount: this.state.promocion.discount,
                offerPrice: this.state.promocion.offerPrice.toString(),
                beginDate: this.state.from,
                endDate: this.state.to,
                status : "activa"
            }
            fetch("https://api-leia.herokuapp.com/offer/update", {
                method: 'POST',
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then(response => {console.log('Success:', response); alert("actualización con éxito")})
                .catch(error => {console.error('Error:', error); alert("ocurrió un error, inténtalo más tarde")});
        }
        else {
            alert("faltan campos")
        }
    }
    cancelarGuardarPromocion = () => {
        this.setState(
            {
                promocion: {
                    id: "",
                    name: "precio del producto",
                    brand: "marca",
                    normalPrice: "",
                    discount : "",
                    offerPrice : "",
                    from : "",
                    to : ""
                }
            }
        )
    }


    render() {

        if( localStorage.getItem('user-role') === "admin") {
            return (
                <div className="module ofertas">
                    <Sidebar type="admin"/>
                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={ofert} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de ofertas</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">
                                <nav>
                                    <ul>
                                        <li onClick={this.visVerTodas} className={"hvr-icon-float " + [ this.state.visibleVerTodas ? "active" : "notActive" ]}><span><FontAwesomeIcon icon={['fas', 'search']} className="hvr-icon"/></span>Ver todas</li>
                                        <li onClick={this.visAgregar} className={"hvr-icon-float " + [this.state.visibleAgregar ? "active" : "notActive"]}><span><FontAwesomeIcon icon={['fas', 'plus']} className="hvr-icon"/></span>Añadir oferta</li>
                                        <li onClick={this.visActualizar} className={"hvr-icon-rotate " + [this.state.visibleActualizar ? "active" : "notActive"]}><span><FontAwesomeIcon icon={['fas', 'sync']} className="hvr-icon"/></span>Actualizar oferta</li>
                                    </ul>
                                </nav>

                                {
                                    this.state.visibleVerTodas ?
                                        (
                                            <section className="ofertas-section ver-todas">
                                                <div className="table-container seventy">
                                                    <table className="ofertas-table">
                                                        <thead>
                                                        <tr>
                                                            <th>Id oferta</th>
                                                            <th>Nombre</th>
                                                            <th>Marca</th>
                                                            <th>Precio normal</th>
                                                            <th>Descuento</th>
                                                            <th>Precio oferta</th>
                                                            <th>Fecha inicio</th>
                                                            <th>Fecha fin</th>
                                                            <th>Estatus</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                            this.state.promociones.map(
                                                                p =>
                                                                    <tr key={p.id + " " + Math.random()}>
                                                                        <td>{p.id}</td>
                                                                        <td>{p.name}</td>
                                                                        <td>{p.brand}</td>
                                                                        <td>{p.normalPrice}</td>
                                                                        <td>{p.discount}% </td>
                                                                        <td>{p.offerPrice}</td>
                                                                        <td>{p.beginDate}</td>
                                                                        <td>{p.endDate}</td>
                                                                        <td>{p.status}</td>
                                                                        <td>{p.status === "activa" ? <button onClick={this.handleIndex}>Eliminar <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button> : <button className="btn-activar" onClick={this.handleIndexReactivar}> Activar <span><FontAwesomeIcon icon={['fas', 'toggle-on']} /></span></button>  } </td>
                                                                    </tr>
                                                            )
                                                        }



                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>
                                        ):
                                        (
                                            null
                                        )
                                }

                                {
                                    this.state.visibleAgregar ?
                                        (
                                            <section className=" ofertas-section agregar">
                                                <div className="step">
                                                    <div className="header">
                                                        <h2>#1</h2>
                                                        <div>
                                                            <p>Ingresa los datos del producto a ofertar</p>
                                                        </div>
                                                        <p>* Da click en "<span><FontAwesomeIcon icon={['fas', 'search']} /></span>" para buscar el producto por id </p>
                                                    </div>
                                                    <div className="content">
                                                        <div className="headers headers-four">
                                                            <label>Id</label>
                                                            <label>Nombre</label>
                                                            <label>Marca</label>
                                                            <label>Precio normal</label>
                                                        </div>
                                                        <div className="inputs">
                                                            <div className="row hvr-icon-rotate">
                                                                <span onClick={this.listProducts}><FontAwesomeIcon icon={['fas', 'search']} className="hvr-icon"/></span>
                                                                <p className="id">{this.state.product.id}id</p>
                                                            </div>
                                                            <p placeholder="nombre del producto">{this.state.product.name}</p>
                                                            <p placeholder="marca del producto" >{this.state.product.brand}</p>
                                                            <p placeholder="precio normal del producto">$ {this.state.product.normalPrice} MXN</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="step">
                                                    <div className="header">
                                                        <h2>#2</h2>
                                                        <div>
                                                            <p>Ingresa los valores de la promocion</p>
                                                        </div>
                                                        <p>* Da click en "<span><FontAwesomeIcon icon={['fas', 'calendar-alt']} /></span>" para seleccionar la fecha </p>
                                                    </div>
                                                    <div className="content">
                                                        <div className="headers headers-four">
                                                            <label>Descuento (%)</label>
                                                            <label>Precio Oferta</label>
                                                            <label>Fecha Inicio</label>
                                                            <label>Fecha Fin</label>
                                                        </div>
                                                        <div className="inputs">
                                                            <input type="text" placeholder="% de descuento" className="discount-input" onChangeCapture={this.calculatePercentage}></input>
                                                            <p placeholder="precio con descuento">$ {this.state.offerPrice} MXN</p>
                                                            <div className="row hvr-icon-rotate">
                                                                <span ><FontAwesomeIcon icon={['fas', 'calendar-alt']} className="hvr-icon"/></span>
                                                                <DayPickerInput
                                                                    placeholder={"Fecha de inicio"}
                                                                    onDayChange={day => this.handleStartDate(day)}
                                                                />
                                                            </div>
                                                            <div className="row hvr-icon-rotate">
                                                                <span onClick={this.getDate} ><FontAwesomeIcon icon={['fas', 'calendar-alt']} className="hvr-icon"/></span>
                                                                <DayPickerInput
                                                                    placeholder={"Fecha de fin"}
                                                                    onDayChange={day => this.handleEndDate(day)}
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                                <div className="step">
                                                    <div className="buttons">
                                                        <button className="green" onClick={this.guardarPromocion}>Guardar <span><FontAwesomeIcon icon={['fas', 'save']} /></span> </button>
                                                        <button className="red" onClick={this.cancelarGuardar}>Cancelar <span><FontAwesomeIcon icon={['fas', 'ban']} /></span></button>
                                                    </div>
                                                </div>
                                            </section>
                                        ):
                                        (
                                            null
                                        )
                                }
                                {
                                    this.state.visibleActualizar ?
                                        (
                                            <section className="ofertas-section actualizar">
                                                <div className="step">
                                                    <div className="header">
                                                        <h2>#1</h2>
                                                        <div>
                                                            <p>Ingresa los datos nuevos de la oferta</p>
                                                        </div>
                                                        <p>* Da click en "<span><FontAwesomeIcon icon={['fas', 'search']} /></span>" para buscar la oferta por id </p>
                                                    </div>
                                                    <div className="content">
                                                        <div className="headers headers-four">
                                                            <label>Id</label>
                                                            <label>Nombre del producto</label>
                                                            <label>Marca</label>
                                                            <label>Precio normal</label>
                                                        </div>
                                                        <div className="inputs">
                                                            <div className="row hvr-icon-rotate">
                                                                <span onClick={this.listPromos}><FontAwesomeIcon icon={['fas', 'search']} className="hvr-icon" /></span>
                                                                <p className="id">{this.state.promocion.id}id</p>
                                                            </div>
                                                            <p placeholder="nombre del producto">{this.state.promocion.name}</p>
                                                            <p placeholder="marca del producto" >{this.state.promocion.brand}</p>
                                                            <p placeholder="precio normal del producto">$ {this.state.promocion.normalPrice} MXN</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step">
                                                    <div className="header">
                                                        <h2>#2</h2>
                                                        <div>
                                                            <p>Ingresa los valores de la oferta</p>
                                                        </div>
                                                        <p>* Da click en "<span><FontAwesomeIcon icon={['fas', 'calendar-alt']} /></span>" para seleccionar la fecha </p>
                                                    </div>
                                                    <div className="content">
                                                        <div className="headers headers-four">
                                                            <label>Descuento (%)</label>
                                                            <label>Precio Oferta</label>
                                                            <label>Fecha Inicio</label>
                                                            <label>Fecha Fin</label>
                                                        </div>
                                                        <div className="inputs">
                                                            <input type="text" placeholder="% de descuento" className="discount-input" onChangeCapture={this.calculatePercentageOffer} placeholder={this.state.promocion.discount}></input>
                                                            <p placeholder="precio con descuento">$ {this.state.promocion.precioOferta} MXN</p>
                                                            <div className="row hvr-icon-rotate">
                                                                <span ><FontAwesomeIcon icon={['fas', 'calendar-alt']} className="hvr-icon"/></span>
                                                                <DayPickerInput
                                                                    placeholder={"Fecha de inicio"}
                                                                    onDayChange={day => this.handleStartDate(day)}
                                                                    selectedDay={this.state.promocion.from}
                                                                />
                                                            </div>
                                                            <div className="row hvr-icon-rotate">
                                                                <span onClick={this.getDate} ><FontAwesomeIcon icon={['fas', 'calendar-alt']} className="hvr-icon"/></span>
                                                                <DayPickerInput
                                                                    placeholder={"Fecha de fin"}
                                                                    onDayChange={day => this.handleEndDate(day)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step">
                                                    <div className="buttons">
                                                        <button className="green" onClick={this.actualizarPromocion}>Guardar <span><FontAwesomeIcon icon={['fas', 'save']} /></span> </button>
                                                        <button className="red" onClick={this.showState}>Cancelar <span><FontAwesomeIcon icon={['fas', 'ban']} /></span></button>
                                                    </div>
                                                </div>

                                            </section>
                                        ):
                                        (
                                            null
                                        )
                                }

                                {/* ----------------- POP UP ELEMENT -----------------  */}

                                {
                                    this.state.popUp ?
                                        (
                                            <div className="popUp popUp-agregar">
                                                <div className="content">
                                                    <span className="close" onClick={this.listProducts}><FontAwesomeIcon icon={['fas', 'times']}/></span>
                                                    <div className="container">
                                                        <h2>Seleccionar un producto</h2>
                                                        <div className="popup-table table-container">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th>id</th>
                                                                    <th>nombre</th>
                                                                    <th>marca</th>
                                                                    <th>precio</th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    this.state.productos.map(
                                                                        p =>
                                                                            <tr key={p.id + " " + Math.random()}>
                                                                                <td>{p.id}</td>
                                                                                <td>{p.name}</td>
                                                                                <td>{p.brand}</td>
                                                                                <td>{p.price}</td>
                                                                                <td><button onClick={this.selectIndexProduct} className="btn-seleccionar">seleccionar</button></td>
                                                                            </tr>
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        (null)
                                }

                                {/* ----------------- POP UP OFFERS ELEMENT -----------------  */}

                                {
                                    this.state.popUpOffer ?
                                        (
                                            <div className="popUp popUp-actualizar">
                                                <div className="content">
                                                    <span className="close" onClick={this.listPromos}><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                    <div className="container">
                                                        <h2>Seleccionar una oferta</h2>
                                                        <div className="popup-table table-container">
                                                            <table>
                                                                <thead>
                                                                <tr>
                                                                    <th>id oferta</th>
                                                                    <th>nombre producto</th>
                                                                    <th>marca</th>
                                                                    <th>precio normal</th>
                                                                    <th>descuento</th>
                                                                    <th>precio con descuento</th>
                                                                    <th>fecha de inicio</th>
                                                                    <th>fecha de fin</th>
                                                                    <th>estatus</th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    this.state.promociones.map(
                                                                        p =>
                                                                            <tr key={p.id + " " + Math.random()}>
                                                                                <td>{p.id}</td>
                                                                                <td>{p.name}</td>
                                                                                <td>{p.brand}</td>
                                                                                <td>{p.normalPrice}</td>
                                                                                <td>{p.discount}</td>
                                                                                <td>{p.offerPrice}</td>
                                                                                <td>{p.beginDate}</td>
                                                                                <td>{p.endDate}</td>
                                                                                <td>{p.status}</td>
                                                                                <td><button onClick={this.selectIndexPromo} className="btn-seleccionar">seleccionar</button></td>
                                                                            </tr>
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        (null)
                                }

                            </div>

                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to={"/sorry-not-logged"} />
            )
        }
    }
}


export default OfertasAdmin;
