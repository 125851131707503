import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class WishListProduct extends Component {

    constructor(props){
        super(props);
    }

    addToCart = (e) => {
        this.props.dialogHandler("loading", "Agregando al carrito")
        if(localStorage.getItem('carrito').length === 0 || localStorage.getItem('carrito') === null || localStorage.getItem('carrito') === "null" ) {
            let carrito = [];
            //carrito.push(e.target.id);
            let p = this.props.product.product_info[0];
            p.cantity = "1";
            carrito.push(p);
            localStorage.setItem('carrito', JSON.stringify(carrito));
            console.log( JSON.parse(localStorage.getItem('carrito')) )
            setTimeout( () => {
                this.props.dialogHandler("ready", "Producto agregado!")
            }, 200 )
            setTimeout( () => {
                this.props.dialogHandler("none", "")
            }, 800 )
        } else {
            //console.log(  JSON.parse(localStorage.getItem('carrito')) );
            let carrito = JSON.parse(localStorage.getItem('carrito'));
            //carrito.push(e.target.id)
            let p = this.props.product.product_info[0];
            p.cantity = "1";
            carrito.push(p);
            localStorage.setItem('carrito', JSON.stringify(carrito));
            setTimeout( () => {
                this.props.dialogHandler("ready", "Producto agregado!")
            }, 1000 )
            setTimeout( () => {
                this.props.dialogHandler("none", "")
            }, 2000 )
        }
    }

    render() {
        return (
            <div key={Math.random()} className={"product"}>
                <div className="image">
                    <img src={this.props.product.product_info[0].photos[0]} />
                </div>
                <div className="info">
                    <p>{this.props.product.product_info[0].brand}</p>
                    <h2>{this.props.product.product_info[0].name}</h2>
                </div>
                <div className="price">
                    <p>Precio</p>
                    <h2>$ {Intl.NumberFormat().format(this.props.product.product_info[0].price)}</h2>
                </div>
                <div className="actions">
                    <div className="buttons">
                        <button className={"delete"} onClick={this.props.delete}  id={this.props.product.key} >  <FontAwesomeIcon icon={['fas', 'trash-alt']}/> Eliminar </button>
                        <button className={"buy"} onClick={this.addToCart} id={this.props.product.key} > <FontAwesomeIcon icon={['fas', 'shopping-cart']} /> Comprar </button>
                    </div>

                </div>
            </div>
        );
    }
}

export default WishListProduct;