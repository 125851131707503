/* eslint-disable */
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MiniCard.scss"

class MiniCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChecked : this.props.selected
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState( {
            isChecked : nextProps.selected
        })
    }

    check = (e) => {
        this.props.event(e.target.id)
    }

    render() {
        return (
            <div id={this.props.index} className={"minicard" + [this.state.isChecked ? " minicard-checked" : null]}  onClick={this.check} >
                <img src={this.props.svg} alt={this.props.nombre} />
                <p> {this.props.nombre } </p>
                <span> <FontAwesomeIcon icon={['fas', 'check']} /> </span>
            </div>
        );
    }
}

export default MiniCard;