import React, {Component} from 'react';
import "./DialogStateless.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DialogStateless = (props) =>
    <div className={"dialog-stateless"}>
        <div className={"container " +  [props.dialog.info.tipo] }>

            {
                props.dialog.info.tipo === "loading" ?
                    (
                        <div>
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin className={"svg"} />
                        </div>
                    ) : null
            }

            {
                props.dialog.info.tipo === "ready" ?
                    (
                        <div>
                            <span className={"closer"} onClick={props.closer} > <FontAwesomeIcon icon={['fas', 'times']} /></span>
                            <FontAwesomeIcon icon={['fas', 'check-circle']} className={"svg"}/>
                        </div>
                    ) : null
            }

            {

                props.dialog.info.tipo === "error" ?
                    (
                        <div>
                            <span className={"closer"} onClick={props.closer} > <FontAwesomeIcon icon={['fas', 'times']} /></span>
                            <FontAwesomeIcon icon={['far', 'times-circle']} className={"svg"}/>
                        </div>
                    ) : null
            }

            {
                <h4>{props.dialog.info.title}</h4>
            }


        </div>
    </div>

export default DialogStateless;