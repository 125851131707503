import React, { Component } from 'react';

import "./Pagos.scss"
import "./../styles/ComponentStyles.sass"
import "./../styles/PopUpSytyles.scss"
import "./../styles/hover.css"
import "./../styles/CardsTable.scss"

import logo from "./../../Images/svg/money.svg"

import Sidebar from "./../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Redirect} from "react-router-dom";

class PagosAdmin extends Component {

    constructor() {
        super()
        this.state = {
            payments: []
        }
    }

    componentWillMount() {
        this.getPayments()
        console.log(this.state.payments)
    }

    getPayments = () => {
        fetch("https://api-leia.herokuapp.com/payment/conekta/getAll").then(response => response.json()).then((myJson) => { this.setState({ payments: myJson.payments }) })
    }




    render() {
        if( localStorage.getItem('user-role') === "cliente" ) {
            return (
                <div className="module pagos pagos-admin">
                    <Sidebar type="admin" />
                    <div className="module-container">
                        <section className="content">

                            <div className="header">
                                <img src={logo} alt="icon" className="icon" />
                                <div className="title">
                                    <h2>Administración de pagos</h2>
                                </div>
                                <hr />
                            </div>

                            <div className="main">

                                <section className="pagos-section ver-todas">
                                    <div className="cards-table-container">
                                        {
                                            this.state.payments.map(
                                                payment =>
                                                    <div className="card-table card-table-client" key={Math.random()}>
                                                        <div className="client sec">
                                                            <p className="title">Cliente:</p>
                                                            <div className="image"><img src={payment.customer_info[0].less.photo} alt={" "} /></div>
                                                            <div className="content">
                                                                <p className="client-name">{payment.customer_info[0].less.name + " " + payment.customer_info[0].less.firstName}</p>
                                                            </div>
                                                        </div>
                                                        <div className={"products sec " + (payment.line_items.length > 1 ? 'products-lg' : 'products-nrml')}>
                                                            <p className="title">Productos:</p>
                                                            <div className="products-items">
                                                                {payment.line_items.map(
                                                                    item =>
                                                                        <div className="product-item" key={Math.random()}>
                                                                            <div className="item-image image">
                                                                                <img src={"https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/products%2FGalaxy-S10-Mobile-Store-Ecuador.jpg?alt=media&token=9bdfad3d-3210-4b8d-8df8-9fc2b0fb5623"} alt={""} />
                                                                                <span>{item.quantity}</span>
                                                                            </div>
                                                                            <div className="content">
                                                                                <p className="item-name">{item.name}</p>
                                                                                <p className="item-price">$ {Intl.NumberFormat().format(item.unit_price)}</p>
                                                                            </div>
                                                                        </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="information sec">
                                                            <p className="title">Información:</p>
                                                            <div>
                                                                <p className="information-address">
                                                                    <span> <FontAwesomeIcon icon={['fas', 'truck-loading']} /> </span>
                                                                    {payment.customer_info[0].less.address}
                                                                </p>
                                                                <p className="information-date">
                                                                    <span> <FontAwesomeIcon icon={['fas', 'calendar-alt']} /> </span>
                                                                    {payment.date}
                                                                </p>
                                                                <p className="information-hour">
                                                                    <span> <FontAwesomeIcon icon={['far', 'clock']} /> </span>
                                                                    {payment.hour}
                                                                </p>
                                                                <p className="information-payment-type">
                                                                    <span> <FontAwesomeIcon icon={['far', 'money-bill-alt']} /> </span>
                                                                    {payment.type}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="total">
                                                            <p className="title">TOTAL:</p>
                                                            <div className="total-content">
                                                                $ {payment.globalTotal ? Intl.NumberFormat().format(payment.globalTotal) : Intl.NumberFormat().format(payment.moreItemsTotal)}
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        }
                                    </div>
                                </section>

                            </div>

                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to={"/sorry-not-access"} />
            )
        }
    }
}

export default PagosAdmin;

