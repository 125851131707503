import React, {Component} from 'react';
import Subcategoria from "./Subcategoria/Categoria"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./../styles/hover.css"

class Categoria extends Component {

    constructor(props){
        super(props)
        this.state = {
            clicked : false
        }
    }

    handleState = (e) => {
        this.setState({
            clicked : !this.state.clicked
        })
        console.log(this.state.clicked)
        if(this.state.clicked === false) {
            e.target.parentNode.parentNode.classList.remove("subs-dont")
        } else {
            e.target.parentNode.parentNode.classList.add("subs-dont")
        }
    }



    render() {
        return (
            <div className="element subs-dont" >
                <div className="section">
                    <label className={"element-title hvr-icon-hang"} onClick={this.handleState}> <span>  {this.state.clicked ? <FontAwesomeIcon icon={['fas', 'arrow-up']} className={"hvr-icon red"} /> : <FontAwesomeIcon icon={['fas', 'arrow-down']} className={"hvr-icon green"} />  }  </span>  {this.props.categoria.name}   </label>
                    <div className="actions" id={this.props.categoria.id} >
                        {
                            /*
                             <FontAwesomeIcon icon={['fas', 'trash-alt']} className={"delete"} onClick={this.props.delete} />
                             <FontAwesomeIcon icon={['fas', 'sync-alt']} className={"update"}  onClick={this.props.update} />
                            */
                        }
                    </div>
                </div>
                <div className="section section-subcategorias">
                    <label className={"element-title"} >Subcategorías:   </label>
                    <ul className={"subcategorias"}>
                        {
                            this.props.categoria.subcategories.map(
                                sub => <Subcategoria key={Math.random()} sub={sub} />
                            )
                        }
                    </ul>
                </div>

            </div>
        );
    }
}

export default Categoria;