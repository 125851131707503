import React, {Component} from 'react';
import "./DialogUpdateService.scss"
import Select from "react-select";
import firebase from "firebase";
import DialogStateless from "./DialogStatelessUpdate/DialogStateless";

class DialogUpdateService extends Component {

    constructor(props){
        super(props)
        this.state = {
            categorias : [],
            cats : [],
            defaultCat : "",
            defaultSubCat : "",
            subcats : [],
            service : { id: "", nombre : "", categoria : "", precio : "", descripcion : " ", subcategoria: "", foto : "https://firebasestorage.googleapis.com/v0/b/leia-mx.appspot.com/o/Servicios%2Fdescarga.png?alt=media&token=09cff4d0-6ec5-45ba-b2a0-16df11137653"  },
            dialog : {
                status : false, info : {}
            }
        }
    }

    componentWillMount() {
        this.setServicio()
        this.getCategorias()
    }

    setServicio = () => {
        this.setState({
            service : {
               id : this.props.servicio.id,
               nombre : this.props.servicio.name,
               categoria : this.props.servicio.principalCategory,
               subcategoria : this.props.servicio.subCategory,
               precio : this.props.servicio.costRange,
               descripcion : this.props.servicio.description,
               foto : this.props.servicio.image
            },
            defaultCat : {value : this.props.servicio.principalCategory, label : this.props.servicio.principalCategory},
            defaultSubCat : {value : this.props.servicio.subCategory, label : this.props.servicio.subCategory}
        })
    }

    getCategorias = () => { // FUNCIONA
        fetch("http://localhost:5000/category/getAll")
            .then(response => response.json())
            .then((myJson) => {
                let cats = myJson.categories.slice(4,8)
                let cat = []
                cats.forEach(
                    ca => cat.push({ label : ca.name, value : ca.name })
                )
                this.setState({
                    categorias : myJson.categories,
                    cats : cat
                })
            })
    }

    handleNewCategory = selectedOptions => {
        this.setState({
            service : {
                ...this.state.service,
                categoria: selectedOptions.value
            }
        })
        let inde;
        this.state.categorias.forEach(
            (c, index) => {
                if(c.name === selectedOptions.value){
                    inde = index
                }
            }
        )
        let subcats = []
        this.state.categorias[inde].subcategories.forEach(
            sub => subcats.push({value: sub.nombre, label:sub.nombre})
        )
        this.setState({
            subcats : subcats
        })
    }

    handleNewSubCategory = selectedOptions => this.setState({
        service : {
            ...this.state.service,
            subcategoria : selectedOptions.value
        }
    });

    handleNombre = e => this.setState({ service : { ...this.state.service,  nombre : e.target.value } })

    handlePrecio = e => this.setState({ service : { ...this.state.service,  precio : e.target.value } })

    handleDescripcion = e => this.setState({ service : { ...this.state.service,  descripcion : e.target.value } })

    updateServicio = (e) => {
        e.preventDefault()
        this.dialogHandler("loading", "Actualizando...")
        let service = {
            id : this.state.service.id,
            name : this.state.service.nombre,
            costRange : this.state.service.precio,
            subCategory : this.state.service.subcategoria,
            principalCategory : this.state.service.categoria,
            image : this.state.service.foto,
            description : this.state.service.descripcion,
            status : "active"
        };
        fetch("http://localhost:5000/quoting/update", {
            method: 'POST',
            body: JSON.stringify(service),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log(response)
                this.dialogHandler("ready", "Guardado!")
                setTimeout(() => this.dialogHandler("none", ""), 700)
                setTimeout(() => this.props.close(), 1400)
            })
            .catch(error => {
                console.error('Error:', error)
                this.dialogHandler("error", "Ocurrió un error")
            });

    }

    clickRef = () => { this.inputImage.click() }

    uploadImage = (e) => {
        if(e.target.files.length > 0){
            let file = e.target.files[0]
            const storageref = firebase.storage().ref(`/Servicios/${file.name}`);
            const task = storageref.put(file)
            this.dialogHandler("loading", "Actualizando imagen")
            task.on(firebase.storage.TaskEvent.STATE_CHANGED,
                {
                    'complete': () => {
                        storageref.getDownloadURL().then(url => {
                            this.setState({
                                service : {
                                    ...this.state.service,
                                    foto : url
                                }
                            })
                            this.dialogHandler("ready", "Imagen actualizada!")
                            setTimeout(() => {
                                this.dialogHandler("none", "")
                            }, 700)
                        })

                    }
                })
        }
    }

    dialogHandler = (tipo, title) => {
        switch (tipo) {
            case "loading" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "ready" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "error" :
                this.setState({
                    dialog : {
                        status : true,
                        info : {
                            title : title,
                            tipo : tipo
                        }
                    }
                })
                break;
            case  "none" :
                this.setState({
                    dialog : {
                        status : false,
                        info : {
                            title : "",
                            tipo : ""
                        }
                    }
                })
                break;
        }
    };


    render() {
        return (
            <div className={"dialog-update-service"}>
                <div className="container">
                    <h1>Agregar un servicio</h1>

                    <div className="form-container">
                        <form>

                            <div className="form-input-image">
                                <img src={this.state.service.foto} alt="Click para cambiar imagen" onClick={this.clickRef}/>
                                <input ref={input => this.inputImage = input} type="file" style={{display:"none"}} onChange={this.uploadImage} accept="image/png, image/jpeg"/>
                            </div>

                            <div className="form-input">
                                <Select options={this.state.cats}  onChange={this.handleNewCategory} value={this.state.defaultCat} />
                                <label>Categoría</label>
                            </div>

                            <div className="form-input">
                                <Select options={this.state.subcats}  onChange={this.handleNewSubCategory} value={this.state.defaultSubCat} />
                                <label>Subcategorias</label>
                            </div>

                            <div className="form-input">
                                <input type="text" placeholder={"Nombre"} value={this.state.service.nombre} onChange={this.handleNombre} />
                                <label>Nombre del servicio</label>
                            </div>

                            <div className="form-input">
                                <input type="text" placeholder={"Ej. 1200-3000"} value={this.state.service.precio} onChange={this.handlePrecio}/>
                                <label>Rango de precio</label>
                            </div>

                            <div className="form-input">
                                <input type="text" placeholder={"Ej. Sitios web..."} value={this.state.service.descripcion} onChange={this.handleDescripcion}/>
                                <label>Descripción</label>
                            </div>

                            <div className="form-input">
                                <button className={"cancel"} onClick={this.props.close}>Cancelar</button>
                                <button onClick={this.updateServicio}>Actualizar</button>
                            </div>


                        </form>
                    </div>

                </div>

                {
                    this.state.dialog.status ?
                        <DialogStateless dialog={this.state.dialog}/> : null
                }

            </div>
        );
    }
}

export default DialogUpdateService;