//eslint-disable
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Servicio.scss"

const Servicio = (props) =>
    <div className={"servicio"}>

        <div className="info">
            <div className="element element-image">
                <img src={props.servicio.image} alt=""/>
            </div>

            <div className="element">
                <label>Nombre</label>
                <p>{props.servicio.name}</p>
            </div>

            <div className="element">
                <label>Categoría</label>
                <p>{props.servicio.principalCategory}</p>
            </div>

            <div className="element">
                <label>Subcategoría</label>
                <p>{props.servicio.subCategory}</p>
            </div>

            <div className="element">
                <label>Costo</label>
                <p>$ {props.servicio.costRange}</p>
            </div>
        </div>

        <div className="actions">
            {
                props.servicio.status === "active" ? <button className={"delete"} id={props.servicio.id} onClick={() => props.delete(props.servicio)}> <FontAwesomeIcon icon={['far', 'trash-alt']}/></button> : <button className={"activate"}  onClick={() => props.activate(props.servicio)}> <FontAwesomeIcon icon={['fas', 'check']}/></button>
            }
            <button className={"update"} id={props.servicio.id} onClick={ () => props.update(props.servicio) }>
                <FontAwesomeIcon icon={['fas', 'sync-alt']}/>
            </button>
        </div>

    </div>

export default Servicio;