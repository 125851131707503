import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ShoppingCartProduct.scss"

class ShoppingCartProduct extends Component {

    constructor(props){
        super(props);
        this.state = {
            cantity : this.props.product.cantity,
            subtotal : this.props.product.cantity * this.props.product.price
        }
    }

    handleCantity = (e) => {
        this.setState( {
            cantity : e.target.value
        })
        this.props.handleCantity(this.props.index, e.target.value)
    }

    aument = () => {
        let cant = Number(this.state.cantity) + 1
        this.setState({
            cantity : cant
        })
        this.props.handleCantity(this.props.index, cant)
    }

    dec = () => {
        if(this.state.cantity > 1 ){
            let cant = Number(this.state.cantity) - 1
            this.setState({
                cantity : cant
            })
            this.props.handleCantity(this.props.index, cant)
        }
    }

    deleteFromShoppingCart = () => {
        this.props.delete(this.props.index)
    }



    render() {
        return (
            <div className={"shopping-cart-product"}>
                <span className="closer" onClick={this.deleteFromShoppingCart}>  <FontAwesomeIcon icon={['fas', 'times']} /> </span>
                <div className="image">
                    <img src={this.props.product.photos[0]} alt=""/>
                </div>
                <div className="info">
                    <h2>{this.props.product.name}</h2>
                    <h3>{this.props.product.brand}</h3>
                </div>
                <div className="cantity">
                    <h2>Cantidad</h2>
                    <div className="detail">
                        <input type="number" value={this.state.cantity} id={this.props.index} onChange={this.handleCantity} min={"1.0"} />
                        <div className="buttons">
                            <button className={"add"} onClick={this.aument}>
                                <FontAwesomeIcon icon={['fas', 'plus']} />
                            </button>
                            <button className={"dec"} onClick={this.dec}>
                                <FontAwesomeIcon icon={['fas', 'minus']} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="subtotal-producto">
                    <p> $ {Intl.NumberFormat().format(this.state.subtotal)} MXN </p>
                </div>
            </div>
        );
    }
}

export default ShoppingCartProduct;